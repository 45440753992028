import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ContentBlockFormError from "./errors/ContentBlockFormError";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import MediaImageUploadComponent from "../media/image/MediaImageUploadComponent";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import StandardTextFormEditorComponent from "../basics/form/StandardTextFormEditorComponent";
import { JsonHelper } from "../../helpers";
import Select from "react-select";
import UrlInternalFormComponent from "../basics/UrlInternalFormComponent";

class ContentBlockCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        console.log(props);

        this.state = {
            type: this.props.type,
            showSettings: CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER),
            show_title: props.model ? props.model.show_title : true,
            show_pages: props.model ? props.model.show_pages : false,
            pages: props.model ? JsonHelper.tryParse(props.model.pages) : [],
            show_content: props.model ? props.model.show_content : true,
            show_image: props.model ? props.model.show_image : true,
            show_url: props.model ? props.model.show_url : false,
            state_active: props.model ? props.model.state_active : false
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        let { show_title, show_pages, pages, show_content, show_image, show_url, state_active } = this.props.values;

        this.setState({
            show_title: show_title ? show_title : this.state.show_title,
            show_pages: show_pages ? show_pages : this.state.show_pages,
            pages: pages ? JsonHelper.tryParse(pages) : [],
            show_content: show_content ? show_content : this.state.show_content,
            show_image: show_image ? show_image : this.state.show_image,
            show_url: show_url ? show_url : this.state.show_url,
            state_active: state_active ? state_active : this.state.state_active
        })
    }

    onChange = (event, custom = null) => {
        let name = event.target.name;

        let value = event.target.value;
        if (this.props.model.id && name === 'show_image' && event.target.type === "checkbox" && this.state[name] && this.originalValues[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);

            this.setState({
                currentEvent: { ...event }
            })
        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);

            this.props.parent.props.parent.handleChange(name, value);
        }

    }

    changeConfirmModal = () => {
        this.state.currentEvent.currentTarget.click();

        this.props.parent.props.parent.handleChange('show_image', false);

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    handleUrlSelect = (e) => {
        let item = e.target.closest('.internal-pages-select-item')
        let url = item.dataset.url;

        url = url.replace("https://", '')

        this.onChange({ target: { name: 'url', value: url } });
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    {this.state.showSettings && (
                        <Col xs={12}>
                            <FormattedMessage id="CMS.ContentBlock.Form.settings">
                                {(value) => <Form.Label>{value}</Form.Label>}
                            </FormattedMessage>
                        </Col>
                    )}

                    {/* title */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {this.state.showSettings && (
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-show-title" label="show_title" name="show_title"
                                        onChange={(event) => {
                                            this.onChange(event, () => this.setState({ show_title: !this.state.show_title }, () => {
                                                delete this.props.values.title;
                                            }));
                                        }}
                                        className={"mb-1 " + (this.props.errors.show_title ? 'form-control is-invalid' : '')}
                                        checked={this.state.show_title ? "checked" : ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.errors.show_title && (
                                            <ContentBlockFormError error={this.props.errors.show_title} />
                                        )}
                                    </Form.Control.Feedback>
                                </Col>
                            )}
                            {this.state.show_title ? (
                                <>
                                    <Col xs={12}>
                                        <FormattedMessage id="CMS.ContentBlock.Form.title">
                                            {(value) => <Form.Label>{value}</Form.Label>}
                                        </FormattedMessage>
                                    </Col>
                                    <Col xs={12}>
                                        <FormattedMessage id="CMS.ContentBlock.Form.title.placeholder">
                                            {(placeholder) => <Form.Control type="text" name="title"
                                                id="title-text" label="CMS.ContentBlock.Form.title"
                                                placeholder={placeholder}
                                                onChange={this.onChange}
                                                isInvalid={this.props.errors.title}
                                                value={this.props.values.title}
                                            />}
                                        </FormattedMessage>

                                        <Form.Control.Feedback type="invalid">
                                            {this.props.errors.title && (
                                                <ContentBlockFormError error={this.props.errors.title} />
                                            )}
                                        </Form.Control.Feedback>
                                    </Col>
                                </>
                            ) : null}
                        </Row>
                    </Form.Group>

                    {/* pages */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {this.state.showSettings && (
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-show-pages" label="show_pages" name="show_pages"
                                        onChange={(event) => this.onChange(event, () => this.setState({ show_pages: !this.state.show_pages }, () => {
                                            delete this.props.values.pages;
                                        }))}
                                        className={"mb-1 " + (this.props.errors.show_pages ? 'form-control is-invalid' : '')}
                                        checked={this.state.show_pages ? "checked" : ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.errors.show_pages && (
                                            <ContentBlockFormError error={this.props.errors.show_pages} />
                                        )}
                                    </Form.Control.Feedback>
                                </Col>
                            )}
                            {this.state.show_pages ? (
                                <>
                                    <Col xs={12}>
                                        <FormattedMessage id="CMS.ContentBlock.Form.pages">
                                            {(value) => <Form.Label>{value}</Form.Label>}
                                        </FormattedMessage>
                                    </Col>
                                    <Col xs={12}>
                                        <Select classNamePrefix="react-select" className={'react-select-container ' + (this.props.errors.pages ? 'is-invalid' : '')} isClearable isMulti
                                            placeholder={<FormattedMessage id="CMS.ContentBlock.Form.pages.placeholder" />}
                                            options={this.props.pages}
                                            value={this.props.pages.filter(page => this.state.pages.indexOf(page.value) > -1)}
                                            onChange={(event) => {
                                                let e = { target: { type: 'select', name: 'pages', value: event ? event.map(e => e.value) : [] } }
                                                this.onChange(e, () => this.setState({ pages: e.target.value }));
                                            }}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            {this.props.errors.pages && <ContentBlockFormError error={this.props.errors.pages} />}
                                        </Form.Control.Feedback>
                                    </Col>
                                </>
                            ) : null}
                        </Row>
                    </Form.Group>

                    {/* content */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {this.state.showSettings && (
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-show-content" label="show_content" name="show_content"
                                        onChange={(event) => this.onChange(event, () => this.setState({ show_content: !this.state.show_content }, () => {
                                            delete this.props.values.content;
                                        }))}
                                        className={"mb-1 " + (this.props.errors.show_content ? 'form-control is-invalid' : '')}
                                        checked={this.state.show_content ? "checked" : ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.errors.show_content && (
                                            <ContentBlockFormError error={this.props.errors.show_content} />
                                        )}
                                    </Form.Control.Feedback>
                                </Col>
                            )}
                            {this.state.show_content ?
                                <>
                                    <Col xs={12}>
                                        <StandardTextFormEditorComponent
                                            {...this.props}
                                            label=""
                                            name="content"
                                            placeholder={"CMS.ContentBlock.Form.content.placeholder"}
                                            onChange={this.onChange}
                                            isInvalid={this.props.errors.content}
                                            value={this.props.values.content}
                                            valueField="content"
                                        />
                                    </Col>
                                </>
                                : null
                            }
                        </Row>
                    </Form.Group>

                    {/* Image */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {this.state.showSettings && (
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-show-image" label="show_image" name="show_image"
                                        onChange={(event) => {
                                            this.onChange(event, () => this.setState({ show_image: !this.state.show_image }, () => {
                                                delete this.props.values.image_width;
                                                delete this.props.values.image_height;
                                            }));
                                        }}
                                        className={"mb-1 " + (this.props.errors.show_image ? 'form-control is-invalid' : '')}
                                        checked={this.state.show_image ? "checked" : ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.errors.show_image && (
                                            <ContentBlockFormError error={this.props.errors.show_image} />
                                        )}
                                    </Form.Control.Feedback>
                                </Col>
                            )}
                            {this.state.showSettings && this.state.show_image ?
                                <>
                                    <Col md={6}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrependEyecatcherWidth">image_width</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control type="text" name="image_width"
                                                aria-describedby="image_width"
                                                onChange={this.onChange}
                                                isInvalid={this.props.errors.image_width}
                                                value={this.props.values.image_width}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {this.props.errors.image_width && (
                                                    <ContentBlockFormError error={this.props.errors.image_width} />
                                                )}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>

                                    <Col md={6}>
                                        <InputGroup>
                                            <InputGroup.Prepend>
                                                <InputGroup.Text id="inputGroupPrependEyecatcherHeight">image_height</InputGroup.Text>
                                            </InputGroup.Prepend>
                                            <Form.Control
                                                aria-describedby="image_height"
                                                type="text"
                                                name="image_height"
                                                onChange={this.onChange}
                                                isInvalid={this.props.errors.image_height}
                                                value={this.props.values.image_height}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {this.props.errors.image_height && (
                                                    <ContentBlockFormError error={this.props.errors.image_height} />
                                                )}
                                            </Form.Control.Feedback>
                                        </InputGroup>
                                    </Col>
                                </> :
                                null
                            }
                            {this.state.show_image ?
                                <Col xs={12}>
                                    <MediaImageUploadComponent
                                        {...this.props}
                                        type="content_block_image"
                                        field="image"
                                        onChange={this.onChange}
                                        isInvalid={this.props.errors.image}
                                        value={this.props.values.image}
                                    />
                                </Col> :
                                null
                            }
                        </Row>
                    </Form.Group>

                    {/* URL */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {this.state.showSettings && (
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-show-url" label="show_url" name="show_url"
                                        onChange={(event) => {
                                            this.onChange(event, () => this.setState({ show_url: !this.state.show_url }));
                                        }}
                                        className={"mb-1 " + (this.props.errors.show_url ? 'form-control is-invalid' : '')}
                                        checked={this.state.show_url ? "checked" : ""}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {this.props.errors.show_url && (
                                            <ContentBlockFormError error={this.props.errors.show_url} />
                                        )}
                                    </Form.Control.Feedback>
                                </Col>
                            )}
                            {this.state.show_url ?
                                <Col xs={12}>
                                    <InputGroup className="no-suffix">
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="url-addon">
                                                https://
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control type="url" name="url" aria-describedby="url"
                                            value={this.props.values.url}
                                            onChange={this.onChange}
                                            isInvalid={this.props.errors.url}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {this.props.errors.url && (<ContentBlockFormError error={this.props.errors.url} />)}
                                        </Form.Control.Feedback>
                                    </InputGroup>

                                    <UrlInternalFormComponent {...this.props} handleUrlSelect={this.handleUrlSelect} />
                                </Col> :
                                null
                            }
                        </Row>
                    </Form.Group>

                    {/* active */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            {this.state.showSettings && (
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-state-active" label="state_active" name="state_active"
                                        onChange={(event) => this.onChange(event, () => this.setState({ state_active: !this.state.state_active }))}
                                        className="mb-1"
                                        checked={this.state.state_active ? "checked" : ""}
                                    />
                                </Col>
                            )}
                        </Row>
                    </Form.Group>
                </Row >

                <DeleteModalComponent
                    parent={this}
                    title={"CMS.ContentBlock.Form.setting.delete.title"} text={"CMS.ContentBlock.Form.setting.delete.text"}
                    buttons={{ confirm: { text: 'Default.confirm', icon: 'check' } }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={(event) => this.setState({ showDeleteModal: false, deleteRow: null })}
                />

            </Form.Group >
        );
    }
}

export default ContentBlockCustomSettingsFormComponent;