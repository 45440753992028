import axios from "axios";
import config from 'react-global-configuration';
import CustomHeaders from "./CustomHeaders";
import MediaRepository from "./MediaRepository";
import API from "../services/api";

export default class PageRepository {
    static all = (website = null, all = false) => API.get('/page/all' + (website || all ? '?' : '') + (website ? 'website=' + website : '') + (all ? 'all=1' : '')).then(response => response.data.pages);
    static tree = () => API.get('/page/all/tree').then(response => response.data.pages);
    static get = (id) => API.get('/page/details/' + id).then(respone => respone.data.page);
    static getBySlug = (slug) => API.get('/page-content?slug=' + slug).then(respone => respone.data.page);
    static getTypes = () => API.get('/page/type/overview');
    static getValidation = (type) => API.get('/page/type/validation?type=' + type);

    static create = (data) => API.post('/page/create', data);
    static update = (data) => API.post('/page/update', data);
    static updateContent = (data) => API.post('/page/update/content', data).then(response => response.data);
    static updateTree = (data) => API.post('/page/update/order', { tree: data }).then(response => response.data);
    static delete = (id) => API.delete('/page/delete?id=' + id);
    static toggle = (id) => API.post('/page/toggle', { id: id }).then(response => {
        if (API.isSuccess(response)) {
            return response.data.active;
        }
    });

    // SEO
    static getSeo = (id) => API.get('/page/seo/index?id=' + id).then(response => response.data.seo);
    static storeSeo = (data) => API.post('/page/seo/store', data);

    // MEDIA
    static getAlbum = (id, album) => {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/media/all?type=' + album + '&page=' + id,
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error.message))
        })
    }

    static getBackgroundVideos = (id, album) => {
        return new Promise((resolve, reject) => {
            PageRepository.getAlbum(id, album)
                .then(response => {
                    let rows = [];
                    let data = response['data'];

                    if (data && data.media !== undefined) {
                        data.media.forEach((row) => {
                            rows.push(row)
                        })
                    }
                    resolve(rows)
                })
                .catch(error => reject(error.message))
        })
    }

    static createEyeCatcher = (page, media, language, item) => {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + "/page/eyecatchers/create",
                {
                    page: page,
                    media: media,
                    language: language,
                    title: item.title,
                    text: item.text,
                    url: item.url,
                    url_text: item.url_text,
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => resolve(response))
                .catch(error => reject(error))
        })
    }

    static editEyeCatcher(page, page_eye_catcher, item) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + "/page/eyecatchers/edit?text=true",
                {
                    page: page,
                    page_eye_catcher: page_eye_catcher,
                    text: item.text,
                    title: item.title,
                    url: item.url,
                    url_text: item.url_text,
                    language: item.language
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => reject(reason))
        })
    }

    static addEyeCatcherText(page, media, item) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + "/page/eyecatchers/edit?text=true",
                {
                    page: page,
                    media: media,
                    text: item.text,
                    title: item.title,
                    url: item.url,
                    url_text: item.url_text,
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    resolve(response)
                })
                .catch(reason => reject(reason))
        })
    }

    static editMediaEyeCatcher(page_id, old_media, new_media) {
        return new Promise((resolve, reject) => {
            axios.post(
                config.get('api_url') + "/page/eyecatchers/edit/media?text=true",
                {
                    page: page_id,
                    old_media_id: old_media,
                    new_media_id: new_media
                },
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(page_response => {
                    if (page_response.data !== undefined && page_response.data.success) {
                        MediaRepository.delete(old_media, 'eye_catcher', page_id)
                            .then(() => resolve(page_response.data.page_eye_catchers))
                            .catch(reason => reject(reason))
                    } else {
                        reject(false)
                    }
                })
                .catch(reason => reject(reason))
        })
    }

    static deleteEyeCatcher(page_id, type, media_item, text = null) {
        return new Promise((resolve, reject) => {
            MediaRepository.delete(media_item.id, type, page_id)
                .then(media_response => {
                    if (media_response.data !== undefined && media_response.data.success) {
                        if (text != null) {
                            axios.post(
                                config.get('api_url') + "/page/eyecatchers/delete?text=true",
                                {
                                    page: page_id,
                                    page_eye_catcher: text.id
                                },
                                {
                                    headers: CustomHeaders.get()
                                }
                            )
                                .then(text_response => {
                                    if (text_response.data !== undefined && text_response.data.success) {
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                })
                                .catch(reason => reject(reason))
                        } else {
                            resolve(true);
                        }
                    } else {
                        reject(false);
                    }
                })
                .catch(reason => reject(reason))
        })
    }

    static getEyeCatchers(id) {
        return new Promise((resolve, reject) => {
            axios.get(
                config.get('api_url') + '/page/eyecatchers?page=' + id + '&text=true',
                {
                    headers: CustomHeaders.get()
                }
            )
                .then(response => {
                    if (API.isSuccess(response)) {
                        resolve(response.data);
                    } else {
                        reject(false);
                    }
                })
                .catch(error => reject(error))
        })
    }

    // NAVIGATION
    static toggleNavigation = (data) => API.post('/page/navigation/toggle', data)
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.navigation;
            }
        });

    static updateNavigation = (data) => API.post('/page/navigation/update', data)
        .then(response => {
            if (API.isSuccess(response)) {
                return response.data.navigation;
            }
        });
}