import React from "react";
import PropTypes from "prop-types";
import FormToastComponent from "../basics/FormToastComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import BootstrapTable from "react-bootstrap-table-next";
import ModalFormFragment from "../../fragments/basics/ModalFormFragment";
import DeleteConfirmComponent from "../basics/DeleteConfirmComponent";
import WebsiteSettingFormComponent from "./WebsiteSettingFormComponent";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import { Link } from "react-router-dom";
import FormattedMessageString from "../basics/FormattedMessageString";
import LoaderComponent from "../basics/layout/LoaderComponent";

class WebsiteSettingsOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showDeleteModal: false,
            rows: this.props.rows,
            website_id: this.props.id
        };

        this.closeDeleteModal = this.closeDeleteModal.bind(this);

        this.columns = [
            {
                dataField: 'key',
                text: 'Key',
                hidden: true
            },
            {
                dataField: 'name',
                text: <FormattedMessageString id="CMS.Settings.overview.table.name" />,
                sort: true,
                style: {
                    width: '20%'
                }
            },
            {
                dataField: 'value',
                text: <FormattedMessageString id="CMS.Settings.overview.table.value" />,
                sort: true,
                style: {
                    width: '65%'
                }
            }];

        if (!GeneralSettingsHelper.is()) {
            this.columns.push({
                datField: 'actions',
                isDummyField: true,
                text: "Actions",
                formatter: (cellContent, row) => {
                    if (GeneralSettingsHelper.is()) return null;

                    return (
                        <div className="actions">
                            {!GeneralSettingsHelper.is() && (
                                <Link to="#" className={"edit"} onClick={() => this.setState({ showEditModal: true, editSetting: row })}>
                                    <FontAwesomeIcon icon={['far', 'edit']} />
                                </Link>
                            )}
                            {
                                !row.isDefault && !GeneralSettingsHelper.is() ?
                                    <Link to="#" className={"delete"} onClick={() => this.openDeleteModal(row)}>
                                        <FontAwesomeIcon icon={['far', 'trash-alt']} />
                                    </Link>
                                    : null
                            }
                        </div>
                    )
                },
                style: { width: '76px' }
            });
        }
    }

    openDeleteModal = (row) => this.setState({ showDeleteModal: true, deleteRow: row })
    closeDeleteModal = () => this.setState({ showDeleteModal: false, deleteRow: null })

    deleteRow = (event, _row) => {
        event.preventDefault();
        this.props.delete_func(this.state.website_id, _row.name)
            .then(response => {
                let rows = this.state.rows;
                rows.website = rows.website.filter(row => {
                    return _row.name !== row.name;
                });

                this.setState({ rows: rows }, () => this.closeDeleteModal())
            })
            .catch(() => FormToastComponent.errorTrans('CMS.Table.delete.error'));
    }

    table = (rows, type) => (
        <div className="custom-data-table website-settings" key={type}>
            <div className="table-wrapper">
                <div className="table-header">
                    <FormattedMessage id={this.props.title_id + "." + type}>
                        {(title) => <p>{title}</p>}
                    </FormattedMessage>
                </div>
                <BootstrapTable keyField="id" bootstrap4 columnToggle
                    data={rows}
                    columns={this.columns}
                    sort={{ dataField: 'name', order: 'asc' }}
                />
            </div>

            <ModalFormFragment title_id={this.props.delete_title_id} is_delete={true}
                show={this.state.showDeleteModal}
                onHide={this.closeDeleteModal}
            >
                <DeleteConfirmComponent onHide={this.closeDeleteModal} delete_text_id={this.props.delete_text_id}
                    delete_func={(event) => this.deleteRow(event, this.state.deleteRow)}
                />
            </ModalFormFragment>

            <ModalFormFragment title_id="CMS.Websites.Settings.edit.title" show={this.state.showEditModal}
                onHide={() => this.setState({ showEditModal: false, editSetting: null })}
            >
                <WebsiteSettingFormComponent parent_ref={this} table_ref={this} setting={this.state.editSetting} />
            </ModalFormFragment>
        </div>
    )

    render() {
        if (this.state.isTableReloading) return <LoaderComponent />;

        if (GeneralSettingsHelper.is()) {
            return this.table(this.state.rows.default, 'default');
        }

        return (
            <>
                {this.table(this.state.rows.website, 'website')}
                <div className={"p-3"} />
                {this.table(this.state.rows.default, 'default')}
            </>
        );
    }
}

WebsiteSettingsOverviewTableComponent.propTypes = {
    id: PropTypes.any,
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    delete_title_id: PropTypes.string.isRequired,
    delete_text_id: PropTypes.string.isRequired,
    delete_func: PropTypes.func.isRequired,
};

export default WebsiteSettingsOverviewTableComponent;