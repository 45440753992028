import * as React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import Website from "../../../models/website/Website";
import StandardFormErrorMessageComponent from "./StandardFormErrorMessageComponent";
import TagsInput from 'react-tagsinput'
import 'react-tagsinput/react-tagsinput.css'
import CustomTextArea from "../CustomTextArea";
import moment, { isMoment } from "moment";
import { InputHelper, PriceHelper } from "../../../helpers";
import FormToastComponent from "../FormToastComponent";
import MediaContentUpload from "../../../models/media/MediaContentUpload";
import SelectSortable from "../../../fragments/form/SelectSortable";

class StandardFormInputComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            options: props.options
        };

        this._formProps = { ...this.props };
        delete this._formProps.title;
        delete this._formProps.errors;
    }

    componentDidMount() {
        setTimeout(() => document.querySelectorAll('input[readonly]').forEach(field => field.removeAttribute('readonly')), 800);
    }

    onChange = (event, value) => {
        if (this.props.fake) {
            this.setState({ value: value })
        } else {
            this._formProps.onChange(event);

            let model = this.props.parent.form.state.values;
            if (this.props.parent.form.state.values instanceof MediaContentUpload) {
                model = new MediaContentUpload(model);
            }

            let names = this._formProps.name.split(".");
            if (names.length === 2) {
                model[names[0]][names[1]] = value;
            } else if (this.props.multi) {
                model[this._formProps.name] = event ? event.map(option => option.value) : []
            } else {
                model[this._formProps.name] = value;
            }

            this.props.parent.setState({ model: model }, () => {
                this.props.parent.form.setState({ values: model }, () => {
                    if (this.props.parent.props.parent.update) {
                        this.props.parent.props.parent.update(model)
                    }
                })
            });
        }

        if (this.props.handleFieldChange) {
            this.props.handleFieldChange(event, value);
        }
    }

    _datetimeTimer = null;
    onDatetimeChange = (event, type, value, currentValue) => {
        value = value.split(type === 'time' ? ':' : '-');

        let newValue = currentValue ? moment(currentValue) : moment();
        if (type === 'date') {
            newValue.year(value[0]).month(value[1] - 1).date(value[2]);
        } else if (type === 'time') {
            newValue.hour(value[0]).minute(value[1]);
        }

        this.onChange(event, newValue.isValid() ? newValue.format('YYYY-MM-DD HH:mm:00') : null);
    }

    render() {
        if (this.state.reload) return 'reload...';

        let errors = this.props.errors;
        let name = this.props.name;
        let _value = null;

        if (this.props.fake) {
            _value = this.state.value;
        } else {
            let names = this._formProps.name.split(".");
            if (names.length === 2) {
                _value = this.props.parent.state.model[names[0]][names[1]];
            } else {
                _value = this.props.parent.state.model[name];
            }
        }

        return (
            <Form.Group>
                {['switch'].indexOf(this.props.type) < 0 && this.renderLabel()}

                <FormattedMessage id={this.props.placeholder ? this.props.placeholder : (this.props.title + '.placeholder')}>
                    {(placeholder) => (
                        this.props.type === "select" ? this.renderSelect(name, _value, placeholder, errors) :
                            this.props.type === "select-creatable" ? this.renderCreatableSelect(name, _value, placeholder, errors) :
                                this.props.type === "url" ? this.renderUrl(name, _value, placeholder, errors) :
                                    this.props.type === "password" ? this.renderPassword(name, _value, placeholder, errors) :
                                        this.props.type === "tags" ? this.renderTags(name, _value, placeholder, errors) :
                                            this.props.type === "switch" ? this.renderSwitch(name, _value, placeholder, errors) :
                                                this.props.type === "textarea" ? this.renderTextarea(name, _value, placeholder, errors) :
                                                    this.props.type === 'datetime' ? this.renderDateTime(name, _value, placeholder, errors) :
                                                        this.props.type === 'date' ? this.renderDate(name, _value, placeholder, errors) :
                                                            this.renderDefault(name, _value, placeholder, errors)
                    )}
                </FormattedMessage>
                {this.props.type !== "url" ?
                    <StandardFormErrorMessageComponent error={this.getError(errors, name)}
                        customFeedback={this._formProps.customFeedback}
                        formErrors={this.props.formErrors}
                        formErrorsName={this.props.formErrorName} />
                    : null
                }

                {this.props.message !== undefined && this.props.message ?
                    <FormattedMessage id={this.props.message}>
                        {(value) => <p className="input-info">{value}</p>}
                    </FormattedMessage> : null
                }
            </Form.Group>
        );
    }

    renderLabel = () => (
        <FormattedMessage id={this.props.title}>
            {value => <Form.Label>
                {value}
                {this.props.optional ?
                    <FormattedMessage id={"CMS.Form.optional"}>
                        {(optional) => <span className={"optional"}>*{optional}</span>}
                    </FormattedMessage>
                    : null
                }
            </Form.Label>
            }
        </FormattedMessage>
    )

    renderSelect = (name, _value, placeholder, errors) => {
        if (this.props.multi && this.props.sortable) {
            return this.renderSortableSelect(name, _value, placeholder, errors);
        }

        let selectedValue = null;
        if (this.props.type === 'select' && _value) {
            if (this.props.multi) {
                selectedValue = this.props.options.filter(option => _value.indexOf(option.value) > -1);
            } else {
                selectedValue = this.props.options.find(({ value }) => value + "" === _value + "");
            }
        }

        return <Select
            {...this._formProps}
            placeholder={this.props.placeholder ? this.props.placeholder : <FormattedMessage id="CMS.Form.select.placeholder" />}
            onChange={(event) => {
                let value = event ? event.value : null;
                if (this.props.multi) value = event;

                if (this.props.beforeOnChange && event) {
                    value = this.props.beforeOnChange(event);
                }
                this.onChange(event, value);
            }}
            value={selectedValue}
            isInvalid={this.getInvalidWrapper(errors, name)}
            className={this.getInvalidWrapper(errors, name) ? 'react-select-container is-invalid' : 'react-select-container'}
            classNamePrefix="react-select"
            isDisabled={this.props.disabled}
            isClearable={this.props.clearable}
            isMulti={this.props.multi}
        />
    }

    renderSortableSelect = (name, _value, placeholder, errors) => {
        return <SelectSortable {...this.props} name={name} value={_value} errors={errors} onChange={this.onChange} />;
    }

    renderCreatableSelect = (name, _value, placeholder, errors) => {
        let selectedValue = null;
        if (this.props.type.indexOf('select') > -1 && _value) {
            if (this.props.multi) {
                selectedValue = this.state.options.filter(option => _value.indexOf(option.value) > -1);
            } else {
                selectedValue = this.state.options.find(({ value }) => value + "" === _value + "");
            }
        }

        return <CreatableSelect
            {...this._formProps}
            options={this.state.options}
            value={selectedValue}
            placeholder={this.props.placeholder ? this.props.placeholder : <FormattedMessage id="CMS.Form.select.placeholder" />}
            onChange={(event) => {
                let value = event ? event.value : null;
                if (this.props.multi) value = event;

                if (this.props.beforeOnChange && event) {
                    value = this.props.beforeOnChange(event);
                }

                if (this.props.multi) {
                    let options = [...this.props.options];
                    if (value) {
                        value.filter(v => v.__isNew__).forEach(v => options.push(v));
                    }
                    this.setState({ options: options });
                }

                this.onChange(event, value);
            }}
            isInvalid={this.getInvalidWrapper(errors, name)}
            className={this.getInvalidWrapper(errors, name) ? 'react-select-container is-invalid' : 'react-select-container'}
            classNamePrefix="react-select"
            isDisabled={this.props.disabled}
            isClearable={this.props.clearable}
            isMulti={this.props.multi}
        />
    }

    renderUrl = (name, _value, placeholder, errors) => (
        <InputGroup>
            <InputGroup.Prepend>
                <InputGroup.Text id="url-addon">
                    https://
                </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
                {...this._formProps}
                aria-describedby="url-addon"
                type="text"
                onChange={(event) => {
                    this.onChange(event, Website.removeHttpOrHttpsInUrl(event.target.value));
                }}
                value={_value}
                placeholder={placeholder}
                isInvalid={this.getInvalidWrapper(errors, name)}
                disabled={this.props.disabled}
            >
            </Form.Control>
            <StandardFormErrorMessageComponent error={this.getError(errors, name)}
                customFeedback={this._formProps.customFeedback}
                formErrors={this.props.formErrors}
                formErrorsName={this.props.formErrorName} />
        </InputGroup>
    )

    renderPassword = (name, _value, placeholder, errors) => (
        <div class="password-toggle-wrap">
            <Form.Control
                {...this._formProps}
                type={this.state.showPasswordPlain ? 'text' : 'password'}
                onChange={(event) => this.onChange(event, event.target.value)}
                value={_value}
                isInvalid={this.getInvalidWrapper(errors, name)}
                placeholder={placeholder}
                disabled={this.props.disabled}
            />
            <div className={"password-toggle"} onClick={() => this.setState({ showPasswordPlain: !this.state.showPasswordPlain })}>
                <FontAwesomeIcon icon={['far', this.state.visible ? 'eye-slash' : 'eye']} />
            </div>
        </div>
    )

    renderTags = (name, _value, placeholder, errors) => (
        <TagsInput value={_value} className={'react-tagsinput ' + (this.getInvalidWrapper(errors, name) ? 'is-invalid' : '')}
            inputProps={{
                className: 'react-tagsinput-input w-100',
                placeholder: placeholder
            }}
            onChange={tags => {
                let model = this.props.parent.state.model;
                model[this._formProps.name] = tags;
                this.props.parent.setState({
                    model: model
                });
            }}
        />
    )

    renderSwitch = (name, _value, placeholder, errors) => (
        <Form.Group className={'navigation-edit custom-settings ' + (this.getInvalidWrapper(errors, name) ? 'is-invalid' : '')}>
            <Form.Group>
                <Form.Check type="switch" checked={_value ? 'checked' : ''} label={this.renderLabel()} className="mb-1 custom-switch"
                    name={name} id={'custom-switch-' + name} disabled={this.props.disabled}
                    onChange={event => {
                        let value = event ? event.target.checked : false;
                        if (this.props.beforeOnChange && event) {
                            value = this.props.beforeOnChange(event);
                        }
                        this.onChange(event, value);
                    }}
                    isInvalid={this.getInvalidWrapper(errors, name)}
                />
                <StandardFormErrorMessageComponent error={this.getError(errors, name)}
                    customFeedback={this._formProps.customFeedback}
                    formErrors={this.props.formErrors}
                    formErrorsName={this.props.formErrorName} />
            </Form.Group>
        </Form.Group>
    )

    renderTextarea = (name, _value, placeholder, errors) => (
        <CustomTextArea
            {...this._formProps}
            onChange={(event) => {
                this.onChange(event, event.target.value);
            }}
            rows={this.props.rows ? this.props.rows : 2}
            isInvalid={this.getInvalidWrapper(errors, name)}
            placeholder={placeholder}
            disabled={this.props.disabled}
        >
            {_value}
        </CustomTextArea>
    )

    renderDateTime = (name, _value, placeholder, errors) => {
        let timestamp = _value ? moment(_value) : null;

        return (
            <Row className={this.getInvalidWrapper(errors, name) ? 'is-invalid' : ''}>
                <Col md={7}>
                    <Form.Control
                        {...this._formProps}
                        type="date"
                        onKeyDown={e => e.preventDefault()}
                        value={timestamp ? timestamp.format('YYYY-MM-DD') : null}
                        onChange={(event) => this.onDatetimeChange(event, 'date', event.target.value, timestamp)}
                        isInvalid={this.getInvalidWrapper(errors, name)}
                        disabled={this.props.disabled}
                    />
                </Col>
                <Col md={5}>
                    <Form.Control
                        {...this._formProps}
                        type="time"
                        onKeyDown={e => e.preventDefault()}
                        className={this._formProps.className + ' text-center'}
                        value={timestamp ? timestamp.format('HH:mm') : null}
                        onChange={(event) => this.onDatetimeChange(event, 'time', event.target.value, timestamp)}
                        isInvalid={this.getInvalidWrapper(errors, name)}
                        disabled={this.props.disabled}
                    />
                </Col>
            </Row>
        );
    }

    renderDate = (name, _value, placeholder, errors) => {
        if (isMoment(_value)) _value = _value.format('YYYY-MM-DD');

        return (
            <Form.Control
                {...this._formProps}
                onChange={(event) => {
                    let value = event ? event.target.value : null;
                    if (this.props.beforeOnChange && event) {
                        value = this.props.beforeOnChange(event);
                    }
                    this.onChange(event, value);
                }}
                onFocus={this.onFocus}
                value={_value}
                isInvalid={this.getInvalidWrapper(errors, name)}
                placeholder={placeholder}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}
            />
        );
    }

    renderDefault = (name, _value, placeholder, errors) => (
        <InputGroup className={this.getInvalidWrapper(errors, name) ? 'is-invalid' : ''}>
            {this.props.addOn && (
                <InputGroup.Prepend>
                    <InputGroup.Text id="currency" className="p-0">
                        <Form.Control as="select" name="settings.membership_price_currency"
                            disabled aria-describedby="membership_price_currency"
                            onChange={(event) => {
                                let value = event ? event.target.value : null;
                                if (this.props.beforeOnChange && event) {
                                    value = this.props.beforeOnChange(event);
                                }
                                this.onChange(event, value);
                            }}
                            isInvalid={this.getInvalidWrapper(errors, name)}

                        >
                            {Object.keys(PriceHelper.getCurrencyList()).map(currency => (
                                <option value={currency} key={currency} dangerouslySetInnerHTML={{ __html: PriceHelper.getCurrencySign(currency) }} />
                            ))}
                        </Form.Control>
                    </InputGroup.Text>
                </InputGroup.Prepend>
            )}
            <Form.Control
                {...this._formProps}
                onChange={(event) => {
                    let value = event ? event.target.value : null;
                    if (this.props.beforeOnChange && event) {
                        value = this.props.beforeOnChange(event);
                    }
                    this.onChange(event, value);
                }}
                onFocus={this.onFocus}
                value={_value}
                isInvalid={this.getInvalidWrapper(errors, name)}
                placeholder={placeholder}
                disabled={this.props.disabled}
                readOnly={this.props.readOnly}

            />
            {this.props.copyToClipBoard && (
                <FontAwesomeIcon className="password-toggle" icon={['far', 'copy']}
                    onClick={() => {
                        InputHelper.copyToClipboard(_value);
                        FormToastComponent.successTrans('API key', 'CMS.Common.copied.to.clipboard');
                    }}
                />
            )}
        </InputGroup>
    )

    getError = (errors, name) => {
        let names = name.split('.');

        if (names.length === 2) {
            if (errors[names[0]] !== undefined) {
                return errors[names[0]][names[1]];
            } else {
                return errors[names[1]];
            }
        } else {
            return errors[name];
        }
    }

    getInvalidWrapper = (errors, name) => {
        let names = name.split('.');
        if (names.length === 2) {
            if (errors[names[0]] !== undefined) {
                return this.getInvalid(!!errors[names[0]][names[1]]);
            } else {
                return this.getInvalid(!!errors[names[1]]);
            }
        } else {
            return this.getInvalid(!!errors[name]);
        }
    }

    getInvalid = (error) => {
        if (this.props.formErrorName && this.props.formErrors !== undefined) {
            return !!error || !!this.props.formErrors[this.props.formErrorName]
        } else {
            return !!error;
        }
    }
}

StandardFormInputComponent.propTypes = {
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired,
    parent: PropTypes.object.isRequired,
    formErrors: PropTypes.array.isRequired,
    formErrorName: PropTypes.string,
    message: PropTypes.string,
    value: PropTypes.any,
    options: PropTypes.array,
    customFeedback: PropTypes.func,
    optional: PropTypes.bool,
    disabled: PropTypes.bool,
    fake: PropTypes.bool
};

export default StandardFormInputComponent;