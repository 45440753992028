import React from "react";
import { Route } from "react-router-dom";
import TopNavFragment from "../fragments/navigation/TopNavFragment";
import LeftNavFragment from "../fragments/navigation/LeftNavFragment";
import ProfileEditFragment from "../fragments/users/ProfileEditFragment";
import { Col, Container, Row } from "react-bootstrap";
import OverviewFragment from "../fragments/basics/OverviewFragment";
import { ContentBlockRepository, CustomerRepository, ModuleRepository, FormRepository, PageRepository, UserRepository, WebsiteRepository } from '../repository';
import FormFragment from "../fragments/basics/FormFragment";
import FormHelper from "../helpers/FormHelper";
import UserRolesEnum from "../enums/UserRolesEnum";
import RoleRoute from "../components/basics/RoleRoute";
import GeneralSettingsHelper from "../helpers/GeneralSettingsHelper";
import Website from "../models/website/Website";
import PolicyComponent from "../components/basics/PolicyComponent";
import ErrorNotFound from './ErrorNotFound';
import Module from "../models/module/Module";
import CMSModulePage from "./CMSModulePage";
import ErrorForbidden from "./ErrorForbidden";
import UserPermissionsComponent from "../components/permissions/UserPermissionsComponent";
import { FormattedMessage } from "react-intl";

class CMSPage extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         roles: this.props.user.roles,
         user: this.props.user,
         showNavOnMobile: false
      };
   }

   updateUser = (user) => this.setState({ user: user })

   render() {
      if (this.state.reload) return 'reloading..';

      return (
         <div className="cms">
            <div className="top-nav">
               <TopNavFragment user={this.state.user} />
            </div>
            <div className={this.state.showNavOnMobile ? "left-nav mobile-open" : "left-nav"}>
               <LeftNavFragment user={this.state.user} parent={this} />
            </div>
            <div className="content">
               <Container fluid={true}>
                  <Row>
                     <Route path="/" component={Index} exact />
                     <RoleRoute exact path={"/modules/overview"} role={UserRolesEnum.MANAGER}
                        roles={this.state.roles}
                        // // extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0')}
                        canShowInGeneralSettings={true}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id="CMS.LeftNav.module.title"
                              add={!GeneralSettingsHelper.is()}
                              text_id={"CMS.Modules.overview.text"}
                              single_title_id="CMS.Modules.overview.single.title"
                              delete_title_id="CMS.Modules.overview.delete.title"
                              delete_text_id="CMS.Modules.overview.delete.text"
                              delete_promise={ModuleRepository.delete}
                              promise={ModuleRepository.all()}
                              slug={"modules"}
                              overviewSlug="/modules/overview"
                              component_name="modules" user={this.state.user} parent={this} />)} />
                     <RoleRoute exact path="/modules/add" role={UserRolesEnum.RESELLER}
                        roles={this.state.roles}
                        render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.Modules.overview.single.title"
                              slug={"modules/overview"} component_name={"modules"}
                              user={this.props.user} parent={this} />)} />
                     <RoleRoute exact path={"/modules/config/:id/:language"} role={UserRolesEnum.MANAGER}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.Modules.config.title"
                              slug="modules/overview"
                              component_name="modules"
                              user={this.props.user} parent={this}
                              get_promise={ModuleRepository.get(routeProps.match.params.id)}
                           />)} />

                     <RoleRoute exact path={"/managers/overview"} role={UserRolesEnum.MANAGER}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment
                              title_id="CMS.TopNavBar.menu.users"
                              text_id="CMS.User.overview.text"
                              single_title_id="CMS.User.overview.single.title"
                              delete_title_id="CMS.User.overview.delete.title"
                              delete_text_id="CMS.User.overview.delete.text"
                              delete_promise={UserRepository.deleteUser}
                              promise={UserRepository.getManagerRows()}
                              slug={"managers"}
                              component_name={"user"} delete_customer_promise={null}
                              add={Website.isNotNull()}
                              user={this.state.user} />
                        )} />
                     {
                        Website.isNotNull() ?
                           <RoleRoute exact path="/managers/add" role={UserRolesEnum.MANAGER}
                              roles={this.state.roles}
                              render={(routeProps) => (
                                 <FormFragment {...routeProps}
                                    single_title_id="CMS.User.overview.single.title"
                                    slug="managers/overview"
                                    component_name={"user"}
                                    user={this.props.user} />)} />
                           : null
                     }
                     <RoleRoute exact path={"/managers/edit/:id"} role={UserRolesEnum.MANAGER}
                        roles={this.state.roles}
                        render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.User.overview.single.title"}
                              slug={"managers/overview"} component_name={"user"}
                              user={this.props.user}
                              get_promise={UserRepository.getUser(routeProps.match.params.id)} />)} />
                     <RoleRoute path={"/google_analytics"} role={UserRolesEnum.WIZARD} roles={this.state.roles} component={test} />
                     <RoleRoute path={"/media"} role={UserRolesEnum.EDITOR} roles={this.state.roles}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps} title_id={"CMS.TopNavBar.menu.media"}
                              text_id={"CMS.Media.overview.text"}
                              single_title_id={"CMS.Media.overview.single.title"}
                              promise={WebsiteRepository.getMediaRows()} slug={"media"}
                              component_name={"media"}
                              delete_title_id={"CMS.User.overview.delete.title"}
                              delete_text_id={"CMS.User.overview.delete.text"}
                              delete_promise={WebsiteRepository.deleteMedia}
                              modal_title={"CMS.Media.add.title"}
                              user={this.state.user}
                              parent={this} />)} />
                     <RoleRoute exact path={"/customers/overview"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.TopNavBar.menu.customers"}
                              single_title_id={"CMS.Customer.overview.single.title"}
                              delete_title_id={"CMS.Customer.overview.delete.title"}
                              delete_text_id={"CMS.Customer.overview.delete.text"}
                              delete_promise={CustomerRepository.deleteCustomer}
                              text_id={"CMS.Customer.overview.text"}
                              promise={CustomerRepository.getCustomerRows()}
                              slug={'customers'}
                              component_name={"user"}
                              delete_customer_promise={null} user={this.state.user} />)} />
                     <RoleRoute exact path={"/customers/add"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles}
                        render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Customer.overview.single.title"}
                              slug={'customers/overview'} component_name={"user"}
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/customers/add/:reseller"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Customer.overview.single.title"}
                              slug={'customers/overview'} component_name={"user"}
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/customers/edit/:id"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Customer.overview.single.title"}
                              slug={'customers/overview'} component_name={"user"}
                              user={this.props.user}
                              get_promise={CustomerRepository.getCustomer(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path={"/managers/resellers/overview"} role={UserRolesEnum.WIZARD}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.TopNavBar.menu.resellers"}
                              single_title_id={"CMS.Reseller.overview.single.title"}
                              delete_title_id={"CMS.Reseller.overview.delete.title"}
                              delete_text_id={"CMS.Reseller.overview.delete.text"}
                              delete_promise={UserRepository.deleteUser}
                              delete_customer_promise={CustomerRepository.deleteCustomer}
                              text_id={"CMS.Reseller.overview.text"}
                              promise={UserRepository.getResellersRows()}
                              slug={'resellers'}
                              component_name={"user"} user={this.state.user} />)} />
                     <RoleRoute exact path={"/managers/resellers/add"} role={UserRolesEnum.WIZARD}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Reseller.overview.single.title"}
                              slug={'managers/resellers/overview'} component_name={"user"}
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/managers/resellers/edit/:id"} role={UserRolesEnum.WIZARD}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.User.overview.single.title"}
                              slug={"managers/resellers/overview"} component_name={"user"}
                              user={this.props.user}
                              get_promise={UserRepository.getUser(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path={"/managers/permissions/:id"} role={UserRolesEnum.MANAGER}
                        roles={this.state.roles} render={(routeProps) => (
                           <UserPermissionsComponent {...routeProps}
                              title_id={"CMS.User.Permissions.title"}
                           />
                        )} />
                     <RoleRoute exact path={"/websites/overview"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.TopNavBar.menu.websites"}
                              text_id={"CMS.Websites.overview.text"}
                              single_title_id={"CMS.Websites.overview.single.title"}
                              delete_title_id={"CMS.Websites.overview.delete.title"}
                              delete_text_id={"CMS.Websites.overview.delete.text"}
                              delete_promise={WebsiteRepository.delete}
                              promise={WebsiteRepository.getWebsiteRows()}
                              slug={"websites"}
                              component_name={"websites"} user={this.state.user} />)} />
                     <RoleRoute exact path={"/websites/add"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Websites.overview.single.title"}
                              slug={"websites/overview"} component_name={"websites"}
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/websites/add/:customer"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Websites.overview.single.title"}
                              slug={"websites/overview"} component_name={"websites"}
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/websites/edit/:id"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Websites.overview.single.title"}
                              slug={"websites/overview"}
                              component_name={"websites"} user={this.props.user}
                              get_promise={WebsiteRepository.get(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path={"/website/translations"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles}
                        extraCheck={localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0'}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.Websites.Translations.overview.title"}
                              text_id={"CMS.Websites.Translations.overview.text"}
                              single_title_id={"CMS.Websites.Translations.overview.single.title"}
                              delete_title_id={"CMS.Websites.Translations.overview.delete.title"}
                              delete_text_id={"CMS.Websites.Translations.overview.delete.text"}
                              delete_promise={WebsiteRepository.deleteTranslation}
                              promise={WebsiteRepository.getTranslations(localStorage.getItem('website.id'))}
                              slug={"website/translations"}
                              component_name={"website-translations"}
                              modal_title={"CMS.Websites.Translations.add.title"}
                              user={this.state.user} />)} />
                     <RoleRoute exact path={"/website/settings"} role={UserRolesEnum.RESELLER}
                        roles={this.state.roles}
                        extraCheck={localStorage.getItem('website.id') != null}
                        canShowInGeneralSettings={true}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id="CMS.Websites.Settings.overview.title"
                              text_id="CMS.Websites.Settings.overview.text"
                              single_title_id="CMS.Websites.Settings.overview.single.title"
                              delete_title_id="CMS.Websites.Settings.overview.delete.title"
                              delete_text_id="CMS.Websites.Settings.overview.delete.text"
                              delete_promise={WebsiteRepository.deleteSetting}
                              promise={WebsiteRepository.getSettings(localStorage.getItem('website.id'))}
                              slug="website/settings"
                              component_name="website-settings"
                              modal_title="CMS.Websites.Settings.Form.add.title"
                              user={this.state.user}
                              add={localStorage.getItem('website.id') !== GeneralSettingsHelper.is()}
                           />
                        )}
                     />
                     <Route exact path="/profile" render={(routeProps) => (
                        <ProfileEditFragment {...routeProps} user={this.props.user} handleUserUpdate={this.updateUser.bind(this)} />)}
                     />

                     {/* Side bar */}
                     <RoleRoute exact path={"/pages/overview"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles}
                        // extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0')}
                        canShowInGeneralSettings={true}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.LeftNav.page.title"}
                              add={!GeneralSettingsHelper.is()}
                              text_id={"CMS.Pages.overview.text"}
                              single_title_id={"CMS.Pages.overview.single.title"}
                              delete_title_id={"CMS.Pages.overview.delete.title"}
                              delete_text_id={"CMS.Pages.overview.delete.text"}
                              delete_promise={PageRepository.delete}
                              promise={PageRepository.all()}
                              slug={"pages"}
                              extraButtons={[
                                 { path: '/navigation/overview', 'text': 'CMS.Pages.overview.manage_navigation', role: 'navigation' }
                              ]}
                              component_name={"pages"} user={this.state.user} parent={this} />)} />


                     <RoleRoute exact path={"/pages/add"} role={UserRolesEnum.MANAGER}
                        roles={this.state.roles}
                        render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Pages.overview.single.title"}
                              slug={'pages/overview'} component_name={"pages"}
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/pages/config/:id/:language"} role={UserRolesEnum.RESELLER}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Pages.config.title"}
                              slug={"pages/overview"}
                              component_name={"pages"}
                              user={this.props.user}
                              get_promise={PageRepository.get(routeProps.match.params.id)}
                           />)} />
                     <RoleRoute exact path={"/pages/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Pages.overview.single.title"}
                              slug={"pages/overview"}
                              overviewSlug="/pages/overview"
                              component_name={"pages-edit"} user={this.props.user}
                              get_promise={PageRepository.get(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path={"/pages/seo/:page/:id/:language"} role={UserRolesEnum.EDITOR}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Pages.seo.title"}
                              slug={"pages/overview"}
                              overviewSlug="/pages/overview"
                              component_name={"pages-seo"} user={this.props.user}
                              get_promise={PageRepository.getSeo(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path={"/pages/photoalbum/:id"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id={"CMS.Pages.photoalbum.title"}
                              slug={"pages/overview"}
                              full={true}
                              component_name={"pages-photo-album"} user={this.props.user}
                              delete_title_id={"CMS.Pages.photoalbum.delete.title"}
                              delete_text_id={"CMS.Pages.photoalbum.delete.text"}
                              text_id={"CMS.Pages.photoalbum.text"}
                              delete_func={FormHelper.fakeDelete}
                              type={"photo_album"}
                              get_promise={PageRepository.getAlbum(routeProps.match.params.id, 'photo_album')} />)} />
                     <RoleRoute exact path={"/pages/videoalbum/:id/:language"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id="CMS.Pages.videoalbum.title"
                              text_id="CMS.Pages.videoalbum.text"
                              single_title_id="CMS.Pages.videoalbum.title.add"
                              overview_title='video_album'
                              promise={PageRepository.getBackgroundVideos(routeProps.match.params.id, 'video_album_' + routeProps.match.params.language)}
                              slug={"video_album_" + routeProps.match.params.language}
                              component_name="media-background-videos"
                              delete_title_id="CMS.Pages.videoalbum.delete.title"
                              delete_text_id="CMS.Pages.videoalbum.delete.text"
                              delete_promise={WebsiteRepository.deleteMedia}
                              modal_title="CMS.Media.Album.videoalbum.modal.title"
                              user={this.state.user}
                              parent={this}
                              goBack="/pages/overview"
                           />
                        )} />
                     <RoleRoute exact path={"/pages/eyecatcher/:id"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.Page.eyecatcher.title"
                              slug="pages/overview"
                              full={true}
                              delete_title_id="CMS.Page.eyecatcher.delete.title"
                              delete_text_id="CMS.Page.eyecatcher.delete.text"
                              delete_func={FormHelper.fakeDelete}
                              type="eye_catcher"
                              component_name="pages-eye-catcher"
                              user={this.props.user}
                              get_promise={PageRepository.getEyeCatchers(routeProps.match.params.id)}
                           />
                        )} />
                     <RoleRoute exact path={"/pages/background-images/:id"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.page.background_images.title"
                              text_id="CMS.page.background_images.text"
                              slug="pages/overview"
                              full={true}
                              component_name="pages-background-images"
                              user={this.props.user}
                              delete_title_id="CMS.Pages.background_images.delete.title"
                              delete_text_id="CMS.Pages.background_images.delete.text"
                              delete_func={FormHelper.fakeDelete}
                              type="background_images"
                              get_promise={PageRepository.getAlbum(routeProps.match.params.id, 'background_images')}
                           />
                        )}
                     />
                     <RoleRoute exact path={"/pages/background-videos/:id"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id="CMS.page.background_videos.title"
                              text_id="CMS.page.background_videos.text"
                              single_title_id="CMS.page.background_videos.single_title"
                              promise={PageRepository.getBackgroundVideos(routeProps.match.params.id, 'background_videos')}
                              slug="background_videos"
                              component_name="media-background-videos"
                              delete_title_id="CMS.page.background_videos.delete.title"
                              delete_text_id="CMS.page.background_videos.delete.text"
                              delete_promise={WebsiteRepository.deleteMedia}
                              modal_title="CMS.Media.Album.background_videos.modal.title"
                              user={this.state.user} parent={this}
                              goBack="/pages/overview"
                           />
                        )}
                     />

                     {/** ContentBlocks Start */}
                     <RoleRoute exact path={"/content-blocks/overview"} role={UserRolesEnum.EDITOR}
                        roles={this.state.roles}
                        // extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0')}
                        canShowInGeneralSettings={true}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.ContentBlock.overview.title"}
                              add={!GeneralSettingsHelper.is()}
                              text_id={"CMS.ContentBlock.overview.text"}
                              single_title_id={"CMS.ContentBlock.overview.single.title"}
                              delete_title_id={"CMS.ContentBlock.overview.delete.title"}
                              delete_text_id={"CMS.ContentBlock.overview.delete.text"}
                              delete_promise={ContentBlockRepository.delete}
                              promise={ContentBlockRepository.all()}
                              slug={"content-blocks"}
                              overviewSlug="/content-blocks/overview"
                              component_name={"content-blocks"} user={this.state.user} />)} />
                     <RoleRoute exact path={"/content-blocks/add"} role={UserRolesEnum.WIZARD}
                        roles={this.state.roles}
                        render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.ContentBlock.overview.single.title"
                              slug="content-blocks/overview" component_name="content-blocks"
                              user={this.props.user} />)} />
                     <RoleRoute exact path={"/content-blocks/edit/:id/:language"} role={UserRolesEnum.EDITOR}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.ContentBlock.overview.single.title"
                              slug="content-blocks/overview"
                              component_name="content-blocks" user={this.props.user}
                              get_promise={ContentBlockRepository.get(routeProps.match.params.id)} />)} />
                     {/** ContentBlocks End */}

                     {/** NAVIGATION */}
                     <RoleRoute exact path={"/navigation/overview"} role={UserRolesEnum.MANAGER}
                        roles={this.state.roles}
                        // extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0')}
                        canShowInGeneralSettings={true}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id={"CMS.LeftNav.navigation.title"}
                              add={false}
                              text_id={"CMS.Navigation.overview.text"}
                              single_title_id={"CMS.Navigation.overview.single.title"}
                              delete_title_id={"CMS.Navigation.overview.delete.title"}
                              delete_text_id={"CMS.Navigation.overview.delete.text"}
                              delete_promise={PageRepository.delete}
                              promise={PageRepository.all()}
                              slug={"navigation"}
                              extraButtons={[
                                 { path: '/pages/overview', 'text': 'CMS.Navigation.overview.manage_pages', role: 'pages' }
                              ]}
                              component_name={"navigation"} user={this.state.user} />)} />

                     {/** FORM start */}
                     <RoleRoute exact path="/forms/overview" role={UserRolesEnum.EDITOR}
                        roles={this.state.roles}
                        canShowInGeneralSettings={true}
                        render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id="CMS.Form.overview.title"
                              add={!GeneralSettingsHelper.is()}
                              text_id="CMS.Form.overview.text"
                              single_title_id="CMS.Form.overview.single.title"
                              delete_title_id="CMS.Form.overview.delete.title"
                              delete_text_id="CMS.Form.overview.delete.text"
                              delete_promise={FormRepository.delete}
                              promise={FormRepository.getRows()}
                              slug="forms"
                              component_name="forms" user={this.state.user} parent={this} />)} />
                     <RoleRoute exact path="/forms/add" role={UserRolesEnum.RESELLER}
                        roles={this.state.roles}
                        render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.Form.overview.single.title"
                              slug="forms/overview" component_name="forms"
                              user={this.props.user} />)} />
                     <RoleRoute exact path="/forms/edit/:id/:language" role={UserRolesEnum.EDITOR}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.Form.overview.single.title"
                              slug="forms/overview" component_name="forms"
                              user={this.props.user}
                              get_promise={FormRepository.get(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path="/forms/mail/:id/:language" role={UserRolesEnum.MANAGER}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <FormFragment {...routeProps}
                              single_title_id="CMS.Form.Form.single.mail-settings"
                              slug="forms/overview" component_name="forms-mail-settings" user={this.props.user}
                              get_promise={FormRepository.get(routeProps.match.params.id)} />)} />
                     <RoleRoute exact path="/forms/entries/:id/:language" role={UserRolesEnum.EDITOR}
                        canShowInGeneralSettings={true}
                        roles={this.state.roles} render={(routeProps) => (
                           <OverviewFragment {...routeProps}
                              title_id="CMS.Form.Entry.overview.title"
                              add={false}
                              goBack="/forms/overview"
                              single_title_id="CMS.Form.Entry.overview.single.title"
                              delete_title_id="CMS.Form.Entry.overview.delete.title"
                              delete_text_id="CMS.Form.Entry.overview.delete.text"
                              promise={FormRepository.get(routeProps.match.params.id, routeProps.match.params.language)}
                              slug="form-entries"
                              component_name="form-entries" user={this.state.user} parent={this} />
                        )} />

                     {/** MODULES LOGIC */}
                     <RoleRoute path="/module" role={UserRolesEnum.EDITOR}
                        roles={this.state.roles}
                        extraCheck={(localStorage.getItem('website.id') != null && localStorage.getItem('website.id') !== '0') && (Module.getId() != null)}
                        canShowInGeneralSettings={false}
                        render={(routeProps) => (<CMSModulePage {...routeProps} parent={this} user={this.state.user} />)}
                     />

                     <Route path="/403-forbidden" component={ErrorForbidden} exact />
                     <Route path="/404-not-found" component={ErrorNotFound} exact />
                  </Row>

               </Container>
               <PolicyComponent />
            </div>
         </div>
      )
   }

   reload = () => this.setState({ reload: true }, () => this.setState({ reload: false }));
}

function Index() {
   return <Col md={12}>
      <div className="title overview-title">
         <FormattedMessage id="CMS.Dashboard.title">
            {value => <h1>{value}</h1>}
         </FormattedMessage>
      </div>

      <FormattedMessage id="CMS.Dashboard.text">
         {value => <p className="overview-text">{value}</p>}
      </FormattedMessage>
   </Col>
}

function test() {
   return <h2>not yet implemented</h2>;
}

export default CMSPage;