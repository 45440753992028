import React from "react";
import PropTypes from "prop-types";
import StandardFormComponent from "../basics/form/StandardFormComponent";
import Website from "../../models/website/Website";
import CustomerRepository from "../../repository/CustomerRepository";
import CountryHelper from "../../helpers/CountryHelper";
import { withRouter } from "react-router-dom";
import WebsiteFormNameError from "./errors/WebsiteFormNameError";
import WebsiteRepository from "../../repository/WebsiteRepository";
import FormToastComponent from "../basics/FormToastComponent";
import FormHelper from "../../helpers/FormHelper";
import WebsiteMaxRequiredError from "./errors/WebsiteMaxRequiredError";
import WebsiteNumberMaxRequiredError from "./errors/WebsiteNumberMaxRequiredError";
import WebsiteMaxError from "./errors/WebsiteMaxError";
import WebsiteHostError from "./errors/WebsiteHostError";
import WebsiteFormWebsiteError from "./errors/WebsiteFormWebsiteError";
import PageRepository from "../../repository/PageRepository";
import { FormattedMessage } from "react-intl";
import MediaImageUploadComponent from "../media/image/MediaImageUploadComponent";
import WebsiteTextFormComponent from "./WebsiteTextFormComponent";
import { LoaderComponent } from "../basics/layout";

class WebsiteFormComponent extends React.Component {
    constructor(props) {
        super(props);

        if (props.id && parseInt(props.id) !== Website.get()) {
            Website.set(props.id);
            window.location.reload();
        }

        this.state = {
            model: this.props.model !== undefined ? this.props.model : new Website(),
            customers: [],
            pages: [],
            isEdit: !!props.id,
            loading: true,
            languages: CountryHelper.getLanguages()
        }

        this.table_ref = React.createRef();

        this.customProps = { ...this.props };
    }

    componentDidMount() {
        let promises = [
            CustomerRepository.getCustomers(),
            PageRepository.all(this.props.id ? this.props.id : -1),
        ];

        Promise.all(promises)
            .then(result => {
                let customerResponse = result[0];
                let pages = result[1];

                if (customerResponse.data) {
                    let pagesOptions = [];
                    if (pages) {
                        pages.forEach(value => pagesOptions.push({ value: value.id, label: value.title }));
                    }

                    this.setState({
                        customers: customerResponse.data.map(value => {
                            return {
                                value: value.id,
                                label: value.contact_info != null ? value.contact_info.firstname + " " + value.contact_info.lastname : value.email
                            };
                        }),
                        pages: pagesOptions
                    }, () => {
                        // edit
                        if (this.props.id != null) {
                            this.props.get_promise
                                .then(response => {
                                    let websiteLanguages = response.languages !== null ? response.languages.map(language => language.language) : [response.default_language !== null ? response.default_language : 'nl'];

                                    let languages = [];
                                    CountryHelper.getLanguages().forEach(language => {
                                        if (websiteLanguages.indexOf(language.value) < 0) return;
                                        languages.push(language);
                                    });

                                    this.setState({
                                        model: new Website(
                                            response.id ? response.id : null,
                                            response.customer !== null ? response.customer : undefined,
                                            response.name !== null ? response.name : undefined,
                                            response.domain !== null ? response.domain : undefined,
                                            response.alternative_homepage !== null ? response.alternative_homepage : undefined,
                                            response.default_language !== null ? response.default_language : 'nl',
                                            response.sftp_host !== null ? response.sftp_host : undefined,
                                            response.sftp_port !== null ? response.sftp_port : undefined,
                                            response.sftp_user !== null ? response.sftp_user : undefined,
                                            undefined,
                                            response.sftp_docroot_path !== null ? response.sftp_docroot_path : undefined,
                                            response.sftp_media_path !== null ? response.sftp_media_path : undefined,
                                            response.api_key !== null ? response.api_key : undefined,
                                            response.logo_svg !== null ? response.logo_svg : undefined,
                                            response.logo !== null ? response.logo : undefined,
                                            response.main_color !== null ? response.main_color : undefined,
                                            response.secondary_color !== null ? response.secondary_color : undefined,
                                            response.tertiary_color !== null ? response.tertiary_color : undefined
                                        ),
                                        isEdit: !!response.id,
                                        languages: languages,
                                        loading: false
                                    })
                                })
                                .catch(error => console.log(error))
                        } else {
                            if (this.props.match.params.customer !== undefined) {
                                let website = this.state.model;
                                website.user = this.props.match.params.customer;
                                this.setState({ model: website, isEdit: false, loading: false })
                            } else {
                                this.setState({ loading: false });
                            }
                        }
                    })
                } else {
                    console.error('NO CUSTOMER FOUND');
                }
            });
    }

    handleError = (error) => {
        if (error.data != null && error.data.message != null) {
            try {
                JSON.parse(error.data.message);
            } catch (e) {
                FormToastComponent.errorTrans("CMS.Website.Form.sft." + error.data.message);
                return;
            }
        }
        FormHelper.handleSubmitError(this, error)

    }

    getBeforeSubmit = (element) => WebsiteTextFormComponent.fields_image_before_submit(element);

    render() {
        if (this.state.loading) return <LoaderComponent />;

        return (
            <StandardFormComponent
                {...this.customProps}
                ref={this.table_ref}
                parent={this}
                className="website"
                validationSchema={Website.schema(this.props.id != null)}
                model={this.state.model}
                formSubmit={(values, formikActions) => {
                    this.getBeforeSubmit.bind();
                    this.getBeforeSubmit(this.table_ref.current)
                        .then(() => {
                            if (this.props.id != null) {
                                WebsiteRepository.update(this.props.id, values)
                                    .then(response => {
                                        FormToastComponent.successTrans("Website", "Default.saved");
                                        this.goBackAfterCreate();
                                    })
                                    .catch(error => this.handleError(error));
                            } else {
                                WebsiteRepository.create(values)
                                    .then(response => {
                                        FormToastComponent.successTrans("Website", "Default.saved");
                                        this.goBackAfterCreate();
                                    })
                                    .catch(error => this.handleError(error));
                            }
                        });
                }}
                formErrors={this.state.form_errors}
                fields={
                    [
                        {
                            type: "row",
                            children: [
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.user",
                                            type: "select",
                                            name: "user",
                                            options: this.state.customers
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.name",
                                            type: "text",
                                            name: "name",
                                            customFeedback: WebsiteFormNameError
                                        },
                                        {
                                            title: "CMS.Website.Form.url",
                                            type: "url",
                                            name: "url",
                                            formErrorName: "domain",
                                            customFeedback: WebsiteFormWebsiteError
                                        },
                                        {
                                            title: "CMS.Website.Form.alternative_url",
                                            type: "select",
                                            name: "alternative_url",
                                            message: "CMS.Website.Form.alternative_url.text",
                                            options: this.state.pages,
                                            disabled: this.state.pages.length <= 0,
                                            placeholder: this.state.pages.length <= 0 ? <FormattedMessage id="CMS.Website.Form.alternative_url.no_pages" /> : null,
                                            clearable: true
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.default_language",
                                            type: "select",
                                            name: "default_language",
                                            options: this.state.languages
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 9,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.sftp.host",
                                            type: "text",
                                            name: "sftp_host",
                                            customFeedback: WebsiteHostError
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 3,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.sftp.port",
                                            type: "text",
                                            name: "sftp_port",
                                            customFeedback: WebsiteNumberMaxRequiredError
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 6,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.sftp.user",
                                            type: "text",
                                            name: "sftp_user",
                                            customFeedback: WebsiteMaxRequiredError,
                                            customProps: { readOnly: true }
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 6,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.sftp.password",
                                            type: "password",
                                            name: "sftp_password",
                                            customFeedback: WebsiteMaxRequiredError
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.sftp.docroot.path",
                                            optional: true,
                                            type: "text",
                                            name: "sftp_docroot_path",
                                            customFeedback: WebsiteMaxError
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.sftp.media.path",
                                            optional: true,
                                            type: "text",
                                            name: "sftp_media_path",
                                            customFeedback: WebsiteMaxError
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 12,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.api_key",
                                            disabled: true,
                                            // hidden: !!this.state.model.id,
                                            type: "text",
                                            name: "api_key",
                                            customFeedback: WebsiteMaxError,
                                            customProps: { copyToClipBoard: !this.state.model.id },
                                            message: !this.state.isEdit ? 'CMS.Website.Form.api_key.text' : ''
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 4,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.main_color",
                                            type: "color",
                                            name: "main_color"
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 4,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.secondary_color",
                                            type: "color",
                                            name: "secondary_color"
                                        }
                                    ]
                                },
                                {
                                    type: "col",
                                    md: 4,
                                    fields: [
                                        {
                                            title: "CMS.Website.Form.tertiary_color",
                                            type: "color",
                                            name: "tertiary_color"
                                        }
                                    ]
                                },
                                this.state.isEdit ? {
                                    type: "col",
                                    md: 12,
                                    fields: [],
                                    custom: MediaImageUploadComponent,
                                    customProps: { label: 'CMS.Website.Form.logo_svg', optional: false, type: 'website_logo_svg', formErrorName: 'logo_svg', module: 'website', field: 'logo_svg' }
                                } : {},
                                {/*
                                    type: "col",
                                    md: 12,
                                    fields: [],
                                    custom: MediaImageUploadComponent,
                                    customProps: { label: 'CMS.Website.Form.logo', type: 'website_logo', module: 'website', field: 'logo' }
                                */}
                            ]
                        }
                    ]}
            >
            </StandardFormComponent >
        );
    }

    goBackAfterCreate = () => this.props.history.push("/" + this.props.slug);
}

WebsiteFormComponent.propTypes = {
    model: PropTypes.object.isRequired,
    table_ref: PropTypes.object.isRequired,
    parent_ref: PropTypes.object.isRequired
}
export default withRouter(WebsiteFormComponent);