import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import ModuleRepository from "../../repository/ModuleRepository";
import EventFormError from './errors/EventFormError';
import moment from "moment/moment";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";
import Module from "../../models/module/Module";
import Select from "react-select";

class EventCustomSettingsFormComponent extends React.Component {
    constructor(props) {
        super(props);

        let lng = localStorage.getItem('language');
        if (this.props.parent.props.match.params.language) {
            lng = this.props.parent.props.match.params.language;
        }

        this.state = {
            use_categories: props.values.settings.use_categories,
            use_references: props.values.settings.use_references,
            reference_modules: props.values.settings.reference_modules ? props.values.settings.reference_modules : [],
            show_start_date: props.values.settings.show_start_date,
            show_hours: props.values.settings.show_hours,
            show_end_date: props.values.settings.show_end_date,
            show_location: props.values.settings.show_location,
            show_rate: props.values.settings.show_rate,
            show_program_pdf: props.values.settings.show_program_pdf,
            show_summary: props.values.settings.show_summary,
            content_with_blocks: props.values.settings.content_with_blocks,
            has_special_blocks: props.values.settings.has_special_blocks,
            show_cover_image: props.values.settings.show_cover_image,
            cover_image_width: props.values.settings.cover_image_width,
            cover_image_height: props.values.settings.cover_image_height,
            need_home_img: props.values.settings.need_home_img,
            home_img_width: props.values.settings.home_img_width,
            home_img_height: props.values.settings.home_img_height,
            use_attendees: props.values.settings.use_attendees,
            use_age_requirements: props.values.settings.use_age_requirements,
            show_minimum_age: props.values.settings.show_minimum_age,
            show_maximum_age: props.values.settings.show_maximum_age,
            switch_date: props.values.settings.switch_date,
            use_health_insurance_certificate: props.values.settings.use_health_insurance_certificate,
            use_tax_certificate: props.values.settings.use_tax_certificate,
            show_photoalbum: props.values.settings.show_photoalbum,
            show_videoalbum: props.values.settings.show_videoalbum,
            categories: [],
            modulesList: [],
            lng: lng
        }

        this.originalValues = this.props.values;
    }

    componentDidMount() {
        ModuleRepository.all()
            .then(result => this.setState({
                categories: result.filter(module => module.module.type === ModuleTypeEnum.CATEGORY),
                use_categories: this.props.values.settings.use_categories,
                modulesList: Module.filterReferences(result).map(module => {
                    return {
                        value: module.id,
                        label: module.title
                    }
                })
            }));
    }

    onChange = (event, custom = null) => {
        let name = event.target.name.replace('settings.', '');
        if (event.target.type === "checkbox" && this.props.values.settings[name] && this.originalValues.settings[name] && this.state.deleteRow !== name) {
            DeleteModalComponent.openDeleteModal(event, name, this);
            this.setState({
                currentEvent: { ...event }
            })

        } else {
            if (custom != null) {
                custom();
            }

            this.props.handleChange(event);
        }
    };

    changeConfirmModal = (event) => {
        this.state.currentEvent.currentTarget.click();

        this.setState({
            showDeleteModal: false,
            deleteRow: null,
            currentEvent: null
        })
    }

    render() {
        return (
            <Form.Group className="custom-settings">
                <Row>
                    <Col xs={12}>
                        <FormattedMessage id="CMS.Event.Form.settings">
                            {(value) => <Form.Label>{value}</Form.Label>}
                        </FormattedMessage>
                    </Col>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-use-categories" name="settings.use_categories" label="use_categories" className="mb-1"
                                    onChange={(event) => this.onChange(event, () => this.setState({ use_categories: !this.state.use_categories }, () => {
                                        delete this.props.values.settings.module;
                                    }))}
                                    checked={this.state.use_categories}
                                />
                            </Col>

                            {this.state.use_categories && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <InputGroup className="large">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">module</InputGroup.Text>
                                                </InputGroup.Prepend>

                                                <Form.Control as="select"
                                                    aria-describedby="module"
                                                    onChange={this.onChange}
                                                    name="settings.module"
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.module}
                                                    value={this.props.values.settings.module}
                                                >
                                                    <option value="">-</option>
                                                    {this.state.categories.map(module => {
                                                        let moduleContent = module.module.contents.filter(content => content.language === this.state.lng)[0];
                                                        return <option value={module.id}>{moduleContent.title}</option>
                                                    })}
                                                </Form.Control>

                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.module && (
                                                        <EventFormError error={this.props.errors.settings.module} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.use_categories">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Use reference */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check
                                    type="switch"
                                    id="custom-switch-use-references"
                                    label="use_references"
                                    name="settings.use_references"
                                    onChange={(event) => {
                                        this.onChange(event, () => {
                                            this.setState({ use_references: !this.state.use_references }, () => {
                                                delete this.props.values.settings.reference_modules;
                                            })
                                        });
                                    }}
                                    className="mb-1"
                                    checked={this.state.use_references}
                                />
                            </Col>

                            {this.state.use_references ?
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <InputGroup className="large mb-1">
                                                    <InputGroup.Prepend>
                                                        <InputGroup.Text id="inputGroupPrependEyecatcherWidth">modules</InputGroup.Text>
                                                    </InputGroup.Prepend>

                                                    <Select classNamePrefix="react-select" isClearable isMulti
                                                        className={'react-select-container w-100 ' + (this.props.errors.settings != null && this.props.errors.settings.reference_modules ? 'react-select-container is-invalid w-100' : '')}
                                                        placeholder={<FormattedMessage id="CMS.Form.select.placeholder" />}
                                                        options={this.state.modulesList}
                                                        onChange={(event) => {
                                                            let e = { target: { type: 'select', name: 'settings.reference_modules', value: event ? event.map(e => e.value) : [] } }
                                                            this.onChange(e, () => this.setState({ reference_modules: e.target.value }));
                                                        }}
                                                        value={this.state.modulesList.filter(module => this.state.reference_modules.indexOf(module.value) > -1)}
                                                    />

                                                    <Form.Control.Feedback type="invalid">
                                                        {this.props.errors.settings != null && this.props.errors.settings.reference_modules && (
                                                            <EventFormError error={this.props.errors.settings.reference_modules} />
                                                        )}
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                                : null}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.team.use_reference">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show start date */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-start-date" label="show_start_date" name="settings.show_start_date" className="mb-1"
                                    onChange={event => this.onChange(event, () => this.setState({ show_start_date: !this.state.show_start_date }, () => {
                                        if (!this.state.show_start_date) {
                                            this.setState({ show_end_date: false, show_hours: false }, () => {
                                                delete this.props.values.settings.show_hours;
                                                delete this.props.values.settings.show_end_date;
                                            })
                                        }
                                    }))}
                                    checked={this.state.show_start_date}
                                />
                            </Col>

                            {this.state.show_start_date && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={12}>
                                            <Form.Check type="switch" label="show_hours" className="mb-1" name="settings.show_hours" id="custom-switch-show-hours"
                                                onChange={event => this.onChange(event, () => this.setState({ show_hours: !this.state.show_hours }))}
                                                checked={this.state.show_hours}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_start_date">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show end date */}
                    {this.state.show_start_date && (
                        <Form.Group as={Col} xs={12}>
                            <Row>
                                <Col xs={12}>
                                    <Form.Check type="switch" id="custom-switch-show-end-date" label="show_end_date" name="settings.show_end_date" className="mb-1"
                                        onChange={event => this.onChange(event, () => this.setState({ show_end_date: !this.state.show_end_date }))}
                                        checked={this.state.show_end_date}
                                    />
                                </Col>

                                <Col xs={12}>
                                    <FormattedMessage id="CMS.Modules.Form.settings.event.show_end_date">
                                        {(values) => <p className="input-info">{values}</p>}
                                    </FormattedMessage>
                                </Col>
                            </Row>
                        </Form.Group>
                    )}

                    {/* Show location */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-location" label="show_location" name="settings.show_location" className="mb-1"
                                    onChange={event => this.onChange(event, () => this.setState({ show_location: !this.state.show_location }))}
                                    checked={this.state.show_location}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_location">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show rate */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-rate" label="show_rate" name="settings.show_rate" className="mb-1"
                                    onChange={event => this.onChange(event, () => this.setState({ show_rate: !this.state.show_rate }))}
                                    checked={this.state.show_rate}
                                />
                            </Col>
                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_rate">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show pdf document */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-program-pdf" label="show_program_pdf" name="settings.show_program_pdf" className="mb-1"
                                    onChange={event => this.onChange(event, () => this.setState({ show_program_pdf: !this.state.show_program_pdf }))}
                                    checked={this.state.show_program_pdf}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_program_pdf">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show summary */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-summary" label="show_summary" name="settings.show_summary" className="mb-1"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_summary: !this.state.show_summary }))}
                                    checked={this.state.show_summary}
                                />
                            </Col>

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_summary">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show content_with_blocks */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-content-with-blocks" label="content_with_blocks" name="settings.content_with_blocks" className="mb-1"
                                    onChange={event => this.onChange(event, () =>
                                        this.setState({ content_with_blocks: !this.state.content_with_blocks }, () => {
                                            delete this.props.values.settings.content_with_blocks_image_width;
                                            delete this.props.values.settings.content_with_blocks_image_height;
                                        })
                                    )}
                                    checked={this.state.content_with_blocks}
                                />
                            </Col>

                            {this.state.content_with_blocks && (
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">content_with_blocks_image_width</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" aria-describedby="content_with_blocks_image_width" name="settings.content_with_blocks_image_width"
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_width}
                                                    value={this.props.values.settings.content_with_blocks_image_width}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_width && (
                                                        <EventFormError error={this.props.errors.settings.content_with_blocks_image_width} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherHeight">content_with_blocks_image_height</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" aria-describedby="content_with_blocks_image_height" name="settings.content_with_blocks_image_height"
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_height}
                                                    value={this.props.values.settings.content_with_blocks_image_height}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.content_with_blocks_image_height && (
                                                        <EventFormError error={this.props.errors.settings.content_with_blocks_image_height} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                        <Col md={12}>
                                            <Form.Check type="switch" label="has_special_blocks" name="settings.has_special_blocks" id="custom-switch-has-special-blocks"
                                                onChange={event => this.onChange(event, () => this.setState({ has_special_blocks: !this.state.has_special_blocks }))}
                                                className="mb-1"
                                                checked={this.state.has_special_blocks}
                                            />
                                        </Col>
                                    </Row>
                                </Form.Group>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.blog.content_with_blocks">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show image */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" label="show_cover_image" className="mb-1"
                                    name="settings.show_cover_image" id="custom-switch-show-cover-image"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_cover_image: !this.state.show_cover_image, need_home_img: false }, () => {
                                        delete this.props.values.settings.cover_image_width;
                                        delete this.props.values.settings.cover_image_height;
                                        this.props.values.settings.need_home_img = false;
                                        delete this.props.values.settings.home_img_width;
                                        delete this.props.values.settings.home_img_height;
                                    }))}
                                    checked={this.state.show_cover_image}
                                />
                            </Col>

                            {this.state.show_cover_image ?
                                <Form.Group className="subsettings">
                                    <Row>
                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">cover_image_width</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control
                                                    aria-describedby="cover_image_width"
                                                    type="text"
                                                    name="settings.cover_image_width"
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.cover_image_width}
                                                    value={this.props.values.settings.cover_image_width}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.cover_image_width && (
                                                        <EventFormError error={this.props.errors.settings.cover_image_width} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                        <Col md={6}>
                                            <InputGroup className="mb-1">
                                                <InputGroup.Prepend>
                                                    <InputGroup.Text id="inputGroupPrependEyecatcherHeight">cover_image_height</InputGroup.Text>
                                                </InputGroup.Prepend>
                                                <Form.Control type="text" name="settings.cover_image_height" aria-describedby="cover_image_height"
                                                    onChange={this.onChange}
                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.cover_image_height}
                                                    value={this.props.values.settings.cover_image_height}
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {this.props.errors.settings != null && this.props.errors.settings.cover_image_height && (
                                                        <EventFormError error={this.props.errors.settings.cover_image_height} />
                                                    )}
                                                </Form.Control.Feedback>
                                            </InputGroup>
                                        </Col>

                                        <Col xs={12}>
                                            <Form.Check type="switch" label="need_home_img" name="settings.need_home_img" id="custom-switch-needs-home-image"
                                                onChange={(event) => this.onChange(event, () => this.setState({ need_home_img: !this.state.need_home_img }, () => {
                                                    delete this.props.values.settings.home_img_width;
                                                    delete this.props.values.settings.home_img_height;
                                                }))}
                                                className="mb-1"
                                                checked={this.state.need_home_img ? "checked" : ""}
                                            />
                                        </Col>

                                        {this.state.need_home_img && (
                                            <Form.Group className="subsettings sub">
                                                <Row>
                                                    <Col md={6}>
                                                        <InputGroup className="mb-1">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text id="inputGroupPrependEyecatcherWidth">home_img_width</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="text" aria-describedby="home_img_width" name="settings.home_img_width"
                                                                onChange={this.onChange}
                                                                isInvalid={this.props.errors.settings != null && this.props.errors.settings.home_img_width}
                                                                value={this.props.values.settings.home_img_width}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.props.errors.settings != null && this.props.errors.settings.home_img_width && (
                                                                    <EventFormError error={this.props.errors.settings.home_img_width} />
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Col>

                                                    <Col md={6}>
                                                        <InputGroup className="mb-1">
                                                            <InputGroup.Prepend>
                                                                <InputGroup.Text id="inputGroupPrependEyecatcherHeight">home_img_height</InputGroup.Text>
                                                            </InputGroup.Prepend>
                                                            <Form.Control type="text" aria-describedby="home_img_height" name="settings.home_img_height"
                                                                onChange={this.onChange}
                                                                isInvalid={this.props.errors.settings != null && this.props.errors.settings.home_img_height}
                                                                value={this.props.values.settings.home_img_height}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.props.errors.settings != null && this.props.errors.settings.home_img_height && (
                                                                    <EventFormError error={this.props.errors.settings.home_img_height} />
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </InputGroup>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        )}

                                        <Col>
                                            <FormattedMessage id="CMS.Modules.Form.settings.event.show_cover_image">
                                                {values => <p className="input-info">{values}</p>}
                                            </FormattedMessage>
                                        </Col>

                                    </Row>
                                </Form.Group>
                                : null
                            }
                        </Row>
                    </Form.Group>

                    {/* Use categories */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check name="settings.use_attendees" type="switch" id="custom-switch-use-attendees" label="use_attendees" className="mb-1"
                                    onChange={event => this.onChange(event, () => this.setState({ use_attendees: !this.state.use_attendees }, () => {
                                        this.props.values.settings.use_age_requirements = false;
                                        this.props.values.settings.show_minimum_age = false;
                                        this.props.values.settings.show_maximum_age = false;
                                        delete this.props.values.settings.switch_date;

                                        this.props.values.settings.use_health_insurance_certificate = false;
                                        this.props.values.settings.use_tax_certificate = false;
                                    }))}
                                    checked={this.state.use_attendees}
                                />
                            </Col>

                            {this.state.use_attendees && (
                                <>
                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check type="switch" label="use_age_requirements" name="settings.use_age_requirements" id="custom-switch-use-age-requirements" className="mb-1"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_age_requirements: !this.state.use_age_requirements, show_minimum_age: false, show_maximum_age: false, switch_date: null }, () => {
                                                        this.props.values.settings.show_minimum_age = false;
                                                        this.props.values.settings.show_maximum_age = false;
                                                        delete this.props.values.settings.switch_date;
                                                    }))}
                                                    checked={this.state.use_age_requirements}
                                                />
                                            </Col>

                                            {this.state.use_age_requirements && (
                                                <Form.Group className="subsettings sub">
                                                    <Row>
                                                        <Col md={12}>
                                                            <Form.Check type="switch" label="show_minimum_age" name="settings.show_minimum_age" id="custom-switch-show-minimum-age" className="mb-1 is-invalid"
                                                                onChange={(event) => this.onChange(event, () => this.setState({ show_minimum_age: !this.state.show_minimum_age }))}
                                                                checked={this.state.show_minimum_age}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.props.errors.settings?.show_minimum_age && (
                                                                    <EventFormError error={this.props.errors.settings.show_minimum_age} />
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </Col>

                                                        <Col md={12}>
                                                            <Form.Check type="switch" label="show_maximum_age" name="settings.show_maximum_age" id="custom-switch-show-maximum-age" className="mb-1 is-invalid"
                                                                onChange={(event) => this.onChange(event, () => this.setState({ show_maximum_age: !this.state.show_maximum_age }))}
                                                                checked={this.state.show_maximum_age}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {this.props.errors.settings?.show_maximum_age && (
                                                                    <EventFormError error={this.props.errors.settings.show_maximum_age} />
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </Col>

                                                        <Col md={12}>
                                                            <InputGroup className={'no-suffix ' + (this.props.errors.settings?.switch_date ? 'is-invalid' : '')}>
                                                                <InputGroup.Prepend>
                                                                    <InputGroup.Text id="inputGroupPrependEyecatcherWidth">switch_date</InputGroup.Text>
                                                                </InputGroup.Prepend>
                                                                <Form.Control type="date" name="settings.switch_date" aria-describedby="switch_date"
                                                                    min="2024-01-01" max="2024-31-12"
                                                                    onChange={this.onChange}
                                                                    isInvalid={this.props.errors.settings != null && this.props.errors.settings.switch_date}
                                                                    value={this.props.values.settings.switch_date ? this.props.values.settings.switch_date : moment().format('YYYY') + '-01-01'}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {this.props.errors.settings?.switch_date && (
                                                                        <EventFormError error={this.props.errors.settings.switch_date} />
                                                                    )}
                                                                </Form.Control.Feedback>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Form.Group>
                                            )}
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check type="switch" label="use_health_insurance_certificate" name="settings.use_health_insurance_certificate" id="custom-switch-use-health-insurance-certificate" className="mb-1"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_health_insurance_certificate: !this.state.use_health_insurance_certificate }))}
                                                    checked={this.state.use_health_insurance_certificate}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>

                                    <Form.Group className="subsettings">
                                        <Row>
                                            <Col md={12}>
                                                <Form.Check type="switch" label="use_tax_certificate" name="settings.use_tax_certificate" id="custom-switch-use-tax-certificate" className="mb-1"
                                                    onChange={event => this.onChange(event, () => this.setState({ use_tax_certificate: !this.state.use_tax_certificate }))}
                                                    checked={this.state.use_tax_certificate}
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                </>
                            )}

                            <Col xs={12}>
                                <FormattedMessage id="CMS.Modules.Form.settings.event.use_attendees">
                                    {values => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show photo_album */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-photoalbum" label="show_photoalbum" name="settings.show_photoalbum" className="mb-1"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_photoalbum: !this.state.show_photoalbum }))}
                                    checked={this.state.show_photoalbum}
                                />
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_photoalbum">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>

                    {/* Show video_album */}
                    <Form.Group as={Col} xs={12}>
                        <Row>
                            <Col xs={12}>
                                <Form.Check type="switch" id="custom-switch-show-videoalbum" label="show_videoalbum" name="settings.show_videoalbum" className="mb-1"
                                    onChange={(event) => this.onChange(event, () => this.setState({ show_videoalbum: !this.state.show_videoalbum }))}
                                    checked={this.state.show_videoalbum}
                                />
                                <FormattedMessage id="CMS.Modules.Form.settings.event.show_videoalbum">
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Col>
                        </Row>
                    </Form.Group>
                </Row >

                <DeleteModalComponent title="CMS.Event.Form.setting.delete.title" text="CMS.Event.Form.setting.delete.text" parent={this}
                    buttons={{ confirm: { text: 'Default.confirm', icon: 'check' } }}
                    customDeleteRowFunction={this.changeConfirmModal}
                    customCloseModalFunction={(event) => this.setState({ showDeleteModal: false, deleteRow: null })}
                />
            </Form.Group >
        );
    }
}

export default EventCustomSettingsFormComponent;