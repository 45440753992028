import React from 'react';
import AuthenticationRepository from "../../repository/AuthenticationRepository";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ProgressBar from "react-bootstrap/ProgressBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import User from "../../models/user/User";
import { Redirect } from "react-router-dom";
import FormToastComponent from "../basics/FormToastComponent";
import TwoFARepository from "../../repository/TwoFARepository";
import DeactivateModalComponent from "./2fa/DeactivateModalComponent";

class ResetPasswordFormComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: User.get(),
            e_password: '',
            password: '',
            c_password: '',
            characters: false,
            big_small_letters: false,
            number: false,
            symbol: false,
            strength: 0,
            redirectToReferrer: false,
            twoFaActivated: false,
            passwordNotSame: false,
            isCorrectPassword: false,
            passwordInvalid: false,
            redirectToLoginOnError: false,
            e_passwordRequired: false,
            form_errors: {}
        };

        this.checkPasswordHandleInputChange = this.checkPasswordHandleInputChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.deactivate2faModal = React.createRef();
    }

    componentDidMount() {
        if (this.props.existing_password) {
            TwoFARepository.check()
                .then(response => this.setState({ twoFaActivated: response.data['enabled'] === 1 }))
                .catch(error => FormToastComponent.errorTrans('Auth.2fa.check.error'));

        } else {
            AuthenticationRepository.resetPasswordFind(this.props.token)
                .catch(() => this.setState({ redirectToLoginOnError: true }))
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        if (name === "c_password" && this.state.passwordNotSame) {
            this.setState({ passwordNotSame: false })
        }
        if (name === "e_password" && this.state.isCorrectPassword) {
            this.setState({ isCorrectPassword: false })
        }
        if (name === "password" && this.state.passwordInvalid) {
            this.setState({ passwordInvalid: false })
        }

        this.setState({ [name]: value })
    }

    checkPasswordHandleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value,
            characters: value.length >= 10,
            big_small_letters: !!(value.match(/([a-z])/) && value.match(/([A-Z])/)),
            number: !!value.match(/([0-9])/),
            symbol: !!value.match(/([!,%,&,@,#,$,^,*,?,_,~, (, ), +,;, :,/,=,€,\\", ',£,.,\\{,\\},\-,[,\]])/),
        }, () => {
            let data = [
                this.state.password !== "",
                this.state.characters,
                this.state.big_small_letters,
                this.state.number,
                this.state.symbol,
            ];

            this.setState({
                strength: data.filter((x, i) => x).length * 20
            })

        });
    }

    handleSubmit(event) {
        event.preventDefault();

        if ((this.state.e_password == null || this.state.e_password === '') && this.props.existing_password !== undefined) {
            this.setState({ e_passwordRequired: true, form_errors: { e_passwordRequired: 'Auth.reset.password.required' } }, () => {
                FormToastComponent.errorTrans('CMS.Form.invalid');
            })
        } else if (this.state.strength !== 100) {
            this.setState({ passwordInvalid: true, password: '', c_password: '', form_errors: { password: 'Auth.reset.password.invalid' } }, () => {
                FormToastComponent.errorTrans('CMS.Form.invalid');
            });
        } else if (this.state.password !== this.state.c_password) {
            this.setState({ passwordNotSame: true, c_password: '', form_errors: { c_password: 'Auth.reset.password.equal' } }, () => {
                FormToastComponent.errorTrans("CMS.Form.invalid'")
            });
        } else if (this.props.existing_password) {
            AuthenticationRepository.resetPasswordByPassword(this.state.e_password, this.state.password, this.state.c_password)
                .then(response => {
                    FormToastComponent.success(response.data.message);
                    this.setState({
                        e_password: '',
                        password: '',
                        c_password: '',
                        characters: false,
                        big_small_letters: false,
                        number: false,
                        symbol: false,
                        strength: 0,
                        passwordInvalid: false,
                        form_errors: {}
                    })
                })
                .catch(reason => {
                    if (reason.data.error != null) {
                        let otherReason = true;

                        if (reason.data.error.current_password !== undefined) {
                            otherReason = false;

                            FormToastComponent.error(reason.data.error.current_password[0]);
                        }
                        if (reason.data.error.new_c_password !== undefined) {
                            otherReason = false;
                            FormToastComponent.error(reason.data.error.new_c_password[0]);
                        }
                        if (reason.data.error.new_password !== undefined) {
                            otherReason = false;
                            FormToastComponent.error(reason.data.error.new_password[0]);
                        }

                        if (otherReason) {
                            FormToastComponent.error(reason.data.error);
                            this.setState({ isCorrectPassword: true, e_password: '' });
                        }
                    }

                })
        } else {
            AuthenticationRepository.resetPassword(this.state.password, this.state.c_password)
                .then(response => {
                    if (response['status'] === 200) {
                        let email = response.data['email'];
                        AuthenticationRepository.login(email, this.state.password)
                            .then(response => {
                                new User({ email: email, token: response.data['success']['token'] }).set();
                                this.setState({ redirectToReferrer: true });
                            })
                            .catch(() => FormToastComponent.errorTrans("Auth.reset.password.failed"))
                    }
                })
                .catch(() => FormToastComponent.errorTrans("Auth.reset.password.failed"));
        }
    }

    render() {
        if (this.state.redirectToReferrer) window.location.href = '/';

        if (this.state.redirectToLoginOnError) return (
            <FormattedMessage id="Auth.reset.password.token.invalid">
                {value => <Redirect to={{ pathname: '/login', state: { error: value } }} />}
            </FormattedMessage>
        );

        return (
            <div className="custom-form">
                <Form onSubmit={this.handleSubmit} id="password-reset-form">
                    {this.props.existing_password && (
                        <Form.Group>
                            <Form.Label><FormattedMessage id="Auth.reset.password.form.existing.password" /></Form.Label>
                            <Form.Control type="password" name="e_password" value={this.state.e_password}
                                onChange={this.handleInputChange}
                                isInvalid={this.state.isCorrectPassword || this.state.e_passwordRequired} />
                            <Form.Control.Feedback type="invalid">
                                {this.state.form_errors.e_passwordRequired && (
                                    <FormattedMessage id={this.state.form_errors.e_passwordRequired}>{value => value}</FormattedMessage>
                                )}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}

                    <Form.Label><FormattedMessage id="Auth.reset.password.form.new.password" /></Form.Label>
                    <Form.Group controlId="formPassword">

                        <FormattedMessage id="Auth.reset.password.form.new.password">
                            {
                                placeholder =>
                                    <>
                                    <div className={"password-toggle-wrap"}>
                                        <Form.Control type={this.state.showPasswordPlain ? 'text' : 'password'} placeholder={placeholder} name="password"
                                            value={this.state.password}
                                            onChange={this.checkPasswordHandleInputChange}
                                            isInvalid={this.state.passwordInvalid}
                                        />
                                        <div  className={"password-toggle"}  onClick={() => this.setState({ showPasswordPlain: !this.state.showPasswordPlain })}>
                                            <FontAwesomeIcon icon={['far', this.state.showPasswordPlain ? 'eye-slash' : 'eye']}/>
                                        </div>
                                    </div>
                                    </>
                            }
                        </FormattedMessage>

                        <Form.Control.Feedback type="invalid">
                            {this.state.form_errors.password && (
                                <FormattedMessage id={this.state.form_errors.password}>{value => value}</FormattedMessage>
                            )}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Label><FormattedMessage id="Auth.reset.password.form.confirm.password" /></Form.Label>
                    <Form.Group controlId="formPasswordConfirm">
                        <FormattedMessage id="Auth.reset.password.form.confirm.password">
                            {
                                placeholder =>
                                    <>
                                    <div className={"password-toggle-wrap"}>
                                        <Form.Control type={this.state.showPasswordPlain ? 'text' : 'password'} placeholder={placeholder} name="c_password"
                                            value={this.state.c_password}
                                            onChange={this.handleInputChange}
                                            isInvalid={this.state.passwordNotSame}
                                        />
                                        <div  className={"password-toggle"}  onClick={() => this.setState({ showPasswordPlain: !this.state.showPasswordPlain })}>
                                            <FontAwesomeIcon icon={['far', this.state.showPasswordPlain ? 'eye-slash' : 'eye']} />
                                        </div>
                                    </div>
                                    </>
                            }
                        </FormattedMessage>

                        <Form.Control.Feedback type="invalid">
                            {this.state.form_errors.c_password && (
                                <FormattedMessage id={this.state.form_errors.c_password}>{value => value}</FormattedMessage>
                            )}
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group>
                        <div className="strength-bar">
                            <ProgressBar now={this.state.strength}
                                className={this.state.strength <= 40 ? 'weak' : this.state.strength <= 80 ? 'normal' : 'strong'} />
                            <p>
                                <FormattedMessage id="Auth.reset.password.form.strength.text">{(txt) => txt}</FormattedMessage>: {this.state.strength <= 40 ?
                                    <FormattedMessage id="Auth.reset.password.form.strength.weak" /> : this.state.strength <= 80 ?
                                        <FormattedMessage id="Auth.reset.password.form.strength.normal" /> :
                                        <FormattedMessage id="Auth.reset.password.form.strength.strong" />}
                            </p>
                        </div>
                        <div className="password-requirements">
                            <ul>
                                <FormattedMessage id="Auth.reset.password.form.password.requirements.title">
                                    {(txt) => <li className="title">{txt}</li>}
                                </FormattedMessage>

                                <FormattedMessage id="Auth.reset.password.form.password.requirements.characters">
                                    {txt =>
                                        <li className={this.state.characters ? 'correct' : ''}><FontAwesomeIcon
                                            icon={['fas', this.state.characters ? 'check-circle' : 'times-circle']} />{txt}
                                        </li>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="Auth.reset.password.form.password.requirements.big_small_letters">
                                    {txt =>
                                        <li className={this.state.big_small_letters ? 'correct' : ''}>
                                            <FontAwesomeIcon
                                                icon={['fas', this.state.big_small_letters ? 'check-circle' : 'times-circle']} />{txt}
                                        </li>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="Auth.reset.password.form.password.requirements.number">
                                    {txt =>
                                        <li className={this.state.number ? 'correct' : ''}><FontAwesomeIcon
                                            icon={['fas', this.state.number ? 'check-circle' : 'times-circle']} />{txt}
                                        </li>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="Auth.reset.password.form.password.requirements.symbol">
                                    {txt =>
                                        <li className={this.state.symbol ? 'correct' : ''}><FontAwesomeIcon
                                            icon={['fas', this.state.symbol ? 'check-circle' : 'times-circle']} />{txt}
                                        </li>
                                    }
                                </FormattedMessage>
                                <FormattedMessage id="Auth.reset.password.form.password.requirements.text">
                                    {(txt) => <li>{txt}</li>}
                                </FormattedMessage>
                            </ul>
                        </div>
                    </Form.Group>
                    <Form.Group controlId="formSubmit">
                        <Button type="submit" onClick={() => {
                            this.setState({
                                passwordInvalid: false,
                                passwordNotSame: false,
                                e_passwordRequired: false

                            })
                        }}><FormattedMessage
                                id={this.props.logged_in ? "Auth.reset.password.form.submit.logged.in" : "Auth.reset.password.form.submit"} /></Button>
                    </Form.Group>
                    {
                        /*this.props.existing_password ?
                            this.state.twoFaActivated ?
                                <Form.Group>
                                    <Button onClick={event => MutateModalComponent.openModal(event, this.deactivate2faModal.current)}><FormattedMessage
                                        id={"Auth.2fa.title.deactivate"} /></Button>
                                </Form.Group>
                                : <Form.Group>
                                    <Link to={"/auth/2fa/activate"}>
                                        <Button><FormattedMessage id={"Auth.2fa.title"} /></Button>
                                    </Link>
                                </Form.Group>
                            : ''*/
                    }
                </Form>
                <DeactivateModalComponent ref={this.deactivate2faModal} component_name={"deactivate2fa"} parent={this} />
            </div>
        )
    }
}

export default ResetPasswordFormComponent;