import React from "react";
import MediaAddFormComponent from "./MediaAddFormComponent";
import MediaRepository from "../../repository/MediaRepository";
import FormToastComponent from "../basics/FormToastComponent";
import Module from "../../models/module/Module";
import MediaHelper from "../../helpers/MediaHelper";
import JsonHelper from "../../helpers/JsonHelper";
import LoaderComponent from "../basics/layout/LoaderComponent";
import FormattedMessageString from "../basics/FormattedMessageString";

class MediaBackgroundVideosAddFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: true
        }

        this.modalRef = null;
    }

    componentDidMount() {
        MediaRepository.getSpecificSettings(this.props.parent_ref.props.slug)
            .then(response => {
                if (response !== undefined && response.data !== undefined && response.data.settings !== undefined) {
                    this.setState({ settings: response.data.settings, rows: [], isLoading: false })
                } else {
                    FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found")
                }
            })
            .catch(() => FormToastComponent.errorTrans("CMS.Media.Album.settings.not.found"))
    }

    showError = () => setTimeout(() => {
        FormToastComponent.errorTrans("CMS.Form.ref.not.found");
        if (this.modalRef) {
            this.modalRef.setState({ showProgress: false, uploadProgress: 0 })
        }
    }, 500)

    customUpload = (values) => {
        let page_id = this.props.parent_ref.props.match.params.id;
        let rows = this.props.parent_ref.state.rows;
        let module = Module.get();

        if (values.media_url !== undefined) {
            MediaRepository.uploadExternal(values.media_url, this.props.parent_ref.props.slug, page_id, values.name, module ? module.module.type : null)
                .then(response => {
                    if (response.data !== undefined) {
                        let external_media = response.data.external_media;
                        let media = response.data.media;

                        MediaRepository.getEmbed(external_media.url)
                            .then(embed_response => {
                                rows.push({
                                    created_at: media.created_at,
                                    file_name: external_media.name,
                                    id: media.id,
                                    mime_type: external_media.type,
                                    name: external_media.name,
                                    size: 0,
                                    updated_at: media.updated_at,
                                    url: embed_response.data ? embed_response.data.thumbnail_url : null
                                });

                                this.props.parent_ref.setState({ rows: rows }, () => {
                                    this.props.parent_ref.setState({ showFormModal: false }, () => {
                                        this.modalRef.setState({ showProgress: false, uploadProgress: 0 }, () => {
                                            FormToastComponent.successTrans(<FormattedMessageString id="CMS.page.background_videos.title" />, "CMS.page.background_videos.upload.success")
                                            this.props.parent_ref.hardReload();
                                        });
                                    });
                                })
                            })
                            .catch(() => this.showError())
                    } else {
                        this.showError();
                    }
                })
                .catch(error => {
                    if (error.response.data !== undefined && error.response.data.message !== undefined) {
                        let errors = JsonHelper.tryParse(error.response.data.message);

                        if (errors.type !== undefined) {
                            errors.type.forEach(error => FormToastComponent.error(error))

                            setTimeout(() => {
                                this.modalRef.setState({ showProgress: false, uploadProgress: 0 })
                            }, 500);
                        } else {
                            this.showError()
                        }
                    } else {
                        this.showError();
                    }
                })
        } else {
            const options = {
                onUploadProgress: (progressEvent) => {
                    const { loaded, total } = progressEvent;
                    let percent = Math.floor((loaded * 100) / total)
                    this.modalRef.setState({ uploadProgress: percent })
                }
            }

            this.modalRef.setState({ showProgress: true })

            MediaRepository.upload(values.media, this.props.parent_ref.props.slug, module ? module.module.type : 'page', page_id, options, values.name)
                .then(response => {
                    if (response.data !== undefined) {
                        let media = response.data.media;
                        rows.push(media);

                        this.props.parent_ref.setState({ rows: rows }, () => {
                            this.props.parent_ref.setState({ showFormModal: false }, () => {
                                this.modalRef.setState({ showProgress: false, uploadProgress: 0 }, () => {
                                    FormToastComponent.successTrans(<FormattedMessageString id="CMS.page.background_videos.title" />, "CMS.page.background_videos.upload.success")
                                    this.props.parent_ref.hardReload();
                                });
                            });
                        })
                    }
                })
                .catch(error => {
                    if (error.response.data !== undefined && error.response.data.message !== undefined) {
                        let errors = JsonHelper.tryParse(error.response.data.message);

                        if (errors.file !== undefined) {
                            errors.file.forEach(error => FormToastComponent.error(error))

                            setTimeout(() => {
                                this.modalRef.setState({ showProgress: false, uploadProgress: 0 })
                            }, 500);
                        } else if (error.response.data.message) {
                            FormToastComponent.errorTrans(error.response.data.message);
                        } else {
                            this.showError();
                        }
                    } else {
                        this.showError();
                    }
                })
        }

    }

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        return <MediaAddFormComponent {...this.props} ref={ref => this.modalRef = ref} customUpload={this.customUpload}
            customAcceptedMimeTypes={() => MediaHelper.acceptedMimeTypes(this.state.settings)}
            customMaxSize={this.state.settings.max_size}
            customSettings={this.state.settings}
        />
    }
}

export default MediaBackgroundVideosAddFormComponent;
