import * as React from "react";
import { Formik } from "formik";
import { Button, Col, Form, ListGroup, Row, Tab } from "react-bootstrap";
import StandardFormInputComponent from "./StandardFormInputComponent";
import FormHelper from "../../../helpers/FormHelper";
import { FormattedMessage } from "react-intl";
import FormattedMessageString from "../FormattedMessageString";
import CheckPermissions from "../../../helpers/CheckPermissions";

class StandardFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            layout: props.layout ? props.layout : 'grid',
            activeTab: props.activeTab,
            model: this.props.model,
            inputs: [],
            oldModel: JSON.stringify(this.props.model),
            user: props.parent.props.user
        };
    }

    fields = [];

    addFieldRef = (ref) => {
        if (ref != null && this.fields.filter(f => f === ref).length === 0) {
            this.fields.push(ref);
        }
    }

    renderInput = (field, onChange, onBlur, errors) => {
        if (this.state.user && field.security && !CheckPermissions.role(this.state.user.roles, field.security)) {
            return null;
        }

        if (field.title != null && field.type != null && field.name != null && !field.hidden) {
            return <StandardFormInputComponent key={field.name} ref={ref => this.addFieldRef(ref)}
                title={field.title} type={field.type} name={field.name} handleFieldChange={field.onChange}
                message={field.message} onChange={onChange} beforeOnChange={field.beforeOnChange} default={field.default}
                onBlur={onBlur} errors={errors} parent={this}
                formErrors={this.props.formErrors} formErrorName={field.formErrorName}
                options={field.options} customFeedback={field.customFeedback} multi={field.multi} sortable={field.sortable}
                optional={field.optional} disabled={field.disabled} placeholder={field.placeholder} clearable={field.clearable} fake={field.fake} {...field.customProps}
            />
        }

        return null;
    }

    custom = (col, values, parent, handleChange, errors, fake) => {
        let Settings = col.custom;
        return <Settings values={values} parent={parent} handleChange={handleChange} errors={errors} fake={fake} ref={ref => this.addFieldRef(ref)} {...col.customProps} />
    }

    onClose = () => this.props.onClose();

    submit = () => {
        let submitEl = document.getElementById('form-submit');
        if (!submitEl) return;

        if (this.props.parent.setGoBackAfterSubmit) {
            this.props.parent.setGoBackAfterSubmit(false);
        }
        submitEl.click();
    }

    render() {
        return (
            <div {...this.props} className={"custom-form " + this.props.className}>
                <Formik
                    enableReinitialize
                    validationSchema={this.props.validationSchema}
                    initialValues={this.props.model}
                    validateOnBlur={false}
                    validateOnChange={false}
                    ref={ref => this.form = ref}
                    onSubmit={this.props.formSubmit}
                >
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                        <Form noValidate onSubmit={handleSubmit} autoComplete="off">
                            <input type="hidden" value="something" />

                            {this.state.layout === 'tab' && this.renderTabbedLayout(values, handleChange, handleBlur, errors)}
                            {this.state.layout === 'grid' && this.renderGridLayout(values, handleChange, handleBlur, errors)}

                            <Row className="buttons">
                                {this.props.formSubmit != null ?
                                    <>
                                        <Col xs={this.props.onClose ? 6 : 12}>
                                            <FormattedMessage id={'CMS.Form.' + this.props.action}>
                                                {value => (
                                                    <Button type="submit" id="form-submit" className={this.props.btnSubmitClass} disabled={this.props.btnSubmitDisabled} onClick={() => FormHelper.submitFormErrorToast(this)}>
                                                        <span>{value}</span>
                                                    </Button>
                                                )}
                                            </FormattedMessage>
                                        </Col>
                                        {this.props.onClose ?
                                            <Col xs={6}>
                                                <FormattedMessage id="Default.cancel">
                                                    {value => <Button variant="secondary" onClick={() => this.props.onClose()}>{value}</Button>}
                                                </FormattedMessage>
                                            </Col>
                                            : null
                                        }
                                    </>
                                    :
                                    <Col xs={12}>
                                        <FormattedMessage id={this.props.btnCloseText}>
                                            {value => <Button variant="primary" onClick={() => this.props.onClose()}>{value}</Button>}
                                        </FormattedMessage>
                                    </Col>
                                }
                            </Row>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    renderTabbedLayout = (values, handleChange, handleBlur, errors) => (
        <div className="tab-tables">
            <Tab.Container id={"list-group-language-Forms"} defaultActiveKey={"#" + this.state.activeTab}>
                <ListGroup>
                    {this.props.fields.map(tab => {
                        if (tab.children === undefined) return null;

                        return (
                            <ListGroup.Item action href={"#" + tab.name} key={tab.name}
                                onClick={() => this.setState({ activeTab: tab.name })}>
                                <FormattedMessageString id={tab.label} />
                            </ListGroup.Item>
                        )
                    })}
                </ListGroup>
                <Tab.Content>
                    {this.props.fields.map(tab => {
                        if (tab.children === undefined) return null;

                        return <Tab.Pane eventKey={"#" + tab.name} key={tab.name}>
                            {tab.children.map(field => {
                                if (field.type === "row" && field.children !== undefined) {
                                    return this.renderRow(field, values, handleChange, handleBlur, errors);
                                } else {
                                    return this.renderInput(field, handleChange, handleBlur, errors, field);
                                }
                            })}

                        </Tab.Pane>
                    })}
                </Tab.Content>
            </Tab.Container>
        </div>
    )

    renderGridLayout = (values, handleChange, handleBlur, errors) => this.props.fields.map((field) => {
        if (field.type === "row" && field.children !== undefined) {
            return this.renderRow(field, values, handleChange, handleBlur, errors);
        } else {
            return this.renderInput(field, handleChange, handleBlur, errors, field);
        }
    });

    renderRow = (field, values, handleChange, handleBlur, errors) => (
        <Row>
            {field.children.map((col, i) => {
                if (col.type === "col") {
                    return <Col xs={col.xs} sm={col.sm} md={col.md} lg={col.lg} xl={col.xl} className={col.className} key={i}>
                        {col.fields.map(field => this.renderInput(field, handleChange, handleBlur, errors))}
                        {col.messageId !== undefined ?
                            <Form.Group>
                                <FormattedMessage id={col.messageId}>
                                    {(values) => <p className="input-info">{values}</p>}
                                </FormattedMessage>
                            </Form.Group>
                            : null
                        }
                        {col.custom !== undefined ?
                            this.custom(col, values, this, handleChange, errors, col.fake) :
                            null
                        }
                    </Col>
                } else {
                    return null;
                }
            })}
        </Row>
    )
}

StandardFormComponent.defaultProps = {
    btnCloseText: 'Default.close'
}

export default StandardFormComponent;