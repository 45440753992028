import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Modal } from "react-bootstrap";
import PageRepository from "../../repository/PageRepository";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Module from "../../models/module/Module";
import ModuleTypeEnum from "../../enums/ModuleTypeEnum";
import Category from "../../models/category/Category";
import { ModuleRepository } from "../../repository";
import { JsonHelper } from "../../helpers";

class UrlInternalFormComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            language: props.language ? props.language : 'nl',
            links: []
        }

        this.toggleModal = this.toggleModal.bind(this);
        this.selectUrl = this.selectUrl.bind(this);
    }

    componentDidMount() {
        Promise.all([
            PageRepository.all(),
            ModuleRepository.all(),
            ModuleRepository.getData()
        ])
            .then(result => {
                let pages = result[0];
                let modules = result[1];
                let categories = result[2].categories;
                let projects = result[2].projects;
                let team = result[2].team;
                let news = result[2].news;

                let allowedCategories = [];
                let linkList = [];
                let moduleData = {};

                // Modules
                if (modules) {
                    modules.forEach(module => {
                        if (!(module.id in moduleData)) moduleData[module.id] = { module: module, items: [] };

                        if (module.module.type === ModuleTypeEnum.CATEGORY) {
                            let item = Module.fromJson(module.module);

                            let moduleCategories = categories.filter(c => c.category.module === module.id);

                            let showContent = Module.getSetting(item, 'content_with_blocks') || Module.getSetting(item, 'show_content');
                            let showContentSubcategories = Module.getSetting(item, 'subcategories_content_with_blocks') || Module.getSetting(item, 'subcategories_show_content');

                            moduleCategories.forEach(category => {
                                if (
                                    (!category.category.parent && showContent) ||
                                    (category.children.length > 0 && showContentSubcategories)
                                ) {
                                    allowedCategories.push(category);
                                } else if (category.category.parent && showContentSubcategories) {
                                    allowedCategories.push(category);
                                }
                            });
                        } else if ([ModuleTypeEnum.PROJECT, ModuleTypeEnum.TEAM, ModuleTypeEnum.NEWS].indexOf(module.module.type) > -1) {
                            if (module.module.type === ModuleTypeEnum.PROJECT) {
                                moduleData[module.id].items = projects.filter(item => item.project.module === module.id);
                            } else if (module.module.type === ModuleTypeEnum.TEAM) {
                                moduleData[module.id].items = team.filter(item => item.team.module === module.id);
                            } else if (module.module.type === ModuleTypeEnum.NEWS) {
                                moduleData[module.id].items = news.filter(item => item.news.module === module.id);
                            }
                        }
                    });
                }

                let categoryTree = allowedCategories.length > 0 ? Category.renderTree(allowedCategories) : {};

                if (pages) {
                    let i = 1; // For homepage
                    pages.forEach(page => {
                        let content = page.page.contents.find(content => content.language === this.state.language);

                        linkList.push({
                            title: content ? content.title : null,
                            url: page.domain + '/' + (content ? content.language : 'nl') + '/permalink/page/' + page.id,
                            disabled: page.page.type === "url" || page.page.type === "dropdown"
                        })

                        if (i > 1 && page.page.modules) {
                            let pageModules = JsonHelper.tryParse(page.page.modules);
                            pageModules.forEach(pageModule => {
                                if (pageModule in categoryTree) {
                                    this.assembleLinkListCategories(linkList, page, categoryTree[pageModule]);
                                } else if (pageModule in moduleData) {
                                    this.assembleLinkList(linkList, page, moduleData[pageModule]);
                                }
                            });
                        }

                        i++;
                    });
                }

                this.setState({ links: linkList });
            });
    }

    assembleLinkListCategories = (list, page, categories, depth = 1) => {
        Object.keys(categories).forEach(id => {
            let category = categories[id];
            let content = category.category.contents.find(content => content.language === this.state.language);

            list.push({
                title: content ? ('-'.repeat(depth) + ' ' + content.title) : null,
                url: page.domain + '/' + (content ? content.language : 'nl') + '/permalink/category/' + category.category.id + '/' + page.id
            });

            if (Object.keys(category.children).length > 0) {
                this.assembleLinkListCategories(list, page, category.children, depth + 1);
            }
        });
    }

    assembleLinkList = (list, page, data, depth = 1) => {
        let type = data.module.module.type;
        data.items.forEach(item => {
            let content = item[type].contents.find(content => content.language === this.state.language);

            list.push({
                title: content ? ('-'.repeat(1) + ' ' + content.title) : null,
                url: page.domain + '/' + (content ? content.language : 'nl') + '/permalink/' + type + '/' + item.id + '/' + page.id + '/' + data.module.id
            });

            if (item.children && item.children.length > 0) {
                this.assembleLinkList(list, page, item.children, depth + 1);
            }
        })
    }

    toggleModal = () => this.setState({ show: !this.state.show })

    selectUrl = (event) => {
        this.props.handleUrlSelect(event);
        this.toggleModal();
    }

    render() {
        return (
            <>
                <FormattedMessage id="CMS.Page.Content.Form.internal.url.link">
                    {
                        (value) => <Link to="#"
                            style={{ color: "#95A58B", fontSize: "13px", textDecoration: "underline" }}
                            onClick={this.toggleModal}>{value}</Link>
                    }
                </FormattedMessage>

                <Modal show={this.state.show} onHide={this.toggleModal} animation={false}
                    className="internal-pages-select-modal" centered>
                    <Modal.Header closeButton>
                        <FormattedMessage id="CMS.Page.Content.Form.internal.url.title">
                            {(value) => <Modal.Title>{value}</Modal.Title>}
                        </FormattedMessage>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="internal-pages-select">
                            {this.state.links.map(link => {
                                let classes = "internal-pages-select-item sub-";
                                if (link.disabled) classes += " disabled";

                                return <div onClick={!link.disabled ? this.selectUrl : null} className={classes} data-url={link.url}>
                                    <p>{link.title}</p>
                                    <FontAwesomeIcon icon={['fal', 'arrow-right']} className={"arrow"} />
                                </div>;
                            })}
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

UrlInternalFormComponent.propTypes = {
    values: PropTypes.array.isRequired,
    parent: PropTypes.object.isRequired,
    handleChange: PropTypes.func.isRequired,
    errors: PropTypes.array.isRequired
};

export default UrlInternalFormComponent;