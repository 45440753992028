import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import StandardTableComponent from "../basics/table/StandardTableComponent";
import DeleteModalComponent from "../basics/table/DeleteModalComponent";
import TableDeleteActionComponent from "../basics/table/TableDeleteActionComponent";
import TableEditActionComponent from "../basics/table/TableEditActionComponent";
import Website from "../../models/website/Website";
import WebsiteRepository from "../../repository/WebsiteRepository";
import { ListGroup, Tab } from "react-bootstrap";
import CountryHelper from "../../helpers/CountryHelper";
import CheckPermissions from "../../helpers/CheckPermissions";
import UserRolesEnum from "../../enums/UserRolesEnum";
import PageOverviewTreeComponent from "./PageOverviewTreeComponent";
import PageRepository from "../../repository/PageRepository";
import FormToastComponent from "../basics/FormToastComponent";
import UserToItemPermissionsComponent from "../permissions/UserToItemPermissionsComponent";
import ToolTipLink from "../basics/ToolTipLink";
import { FormattedMessage } from "react-intl";
import GeneralSettingsHelper from "../../helpers/GeneralSettingsHelper";
import { Link } from "react-router-dom";
import Application from "../../helpers/Application";
import StandardTableFormInputComponent from "../basics/table/StandardTableFormInputComponent";
import cellEditFactory from "react-bootstrap-table2-editor";
import FormattedMessageString from "../basics/FormattedMessageString";
import ModuleRepository from "../../repository/ModuleRepository";
import JsonHelper from "../../helpers/JsonHelper";
import TableHelper from "../../helpers/TableHelper";
import Module from "../../models/module/Module";
import moment from 'moment';
import LoaderComponent from "../basics/layout/LoaderComponent";

class PageOverviewTableComponent extends React.Component {

    constructor(props) {
        super(props);

        this._userPermissions = React.createRef();

        this.state = {
            rows: this.props.rows,
            type: this.props.type,
            website: null,
            isLoading: true,
            toggler: [],
            treeData: [],
            language: Application.tableLanguage(),
            languages: Application.getLanguages()
        };

    }

    componentDidMount() {
        let websiteId = !GeneralSettingsHelper.is() ? Website.get() : 1;

        let promises = [
            WebsiteRepository.get(websiteId),
            ModuleRepository.all()
        ];

        Promise.all(promises)
            .then(response => {
                let modules = {};
                if (response[1]) {
                    response[1].forEach(module => {
                        modules[module.id] = module;
                    });
                }

                this.setState({
                    website: response[0],
                    modules: modules,
                    isLoading: false
                })
            });

        document.addEventListener('keyup', this.initEscListener);
    }

    componentWillUnmount() {
        document.removeEventListener('keyup', this.initEscListener);
    }

    columns = (index) => {

        if (GeneralSettingsHelper.is()) {
            return [
                {
                    dataField: 'id',
                    hidden: true,
                    text: '',
                    editable: false
                },
                {
                    dataField: 'placeholder',
                    isDummyField: true,
                    text: '',
                    editable: false
                },
                {
                    dataField: 'title',
                    text: <FormattedMessageString id="CMS.Pages.overview.table.name" />,
                    sort: true,
                    isDummyField: true,
                    formatter: (cellContent, row) => {
                        let content = row.page.contents[index];
                        if (content !== undefined)
                            return content.title;
                        else
                            return null;
                    }
                }, {
                    dataField: 'updated',
                    text: <FormattedMessageString id="CMS.Pages.overview.table.last_updated" />,
                    isDummyField: true,
                    editable: false,
                    formatter: (cellContent, row) => {
                        let contents = row.page.contents[index];
                        if (contents === undefined) return null;
                        return (
                            <div className="updated_by">
                                {moment(contents.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                                {contents.updated_by && (<><br /><span className="small-highlighted-text"><FormattedMessageString id="CMS.Pages.overview.table.updated_by" /> {contents.updated_by}</span></>)}
                            </div>
                        )
                    }
                },

                {
                    dataField: 'actions',
                    isDummyField: true,
                    text: '',
                    hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                    editable: false,
                    headerStyle: (colum, colIndex) => {
                        return { width: '1%' };
                    },
                    formatter: (cellContent, row) => {
                        return <div className="actions">
                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && (['none', 'dropdown', 'module'].indexOf(row.page.type.toString()) < 0) ?
                                <TableEditActionComponent id={row.page.id} disabled={row.page.type.toString() === "sitemap"}
                                    custom={row.page.contents[index] !== undefined ? '/' + row.page.contents[index].language : '/'} />
                                : null
                            }
                        </div>
                    }
                }
            ]
        }


        return [
            {
                dataField: 'id',
                hidden: true,
                text: '',
                editable: false
            },
            {
                dataField: 'placeholder',
                isDummyField: true,
                text: '',
                editable: false
            },
            {
                dataField: 'title',
                text: <FormattedMessageString id="CMS.Pages.overview.table.name" />,
                sort: false,
                isDummyField: true,
                formatter: (cellContent, row) => {
                    let content = row.page.contents[index];
                    if (content !== undefined)
                        return content.title;
                    else
                        return null;
                },
                editorRenderer: (editorProps, value, row, column, rowIndex, columnIndex) => {
                    let content = row.page.contents[index];
                    if (content !== undefined)
                        value = content.title;

                    if (editorProps.defaultValue) {
                        editorProps.defaultValue = undefined;
                    }

                    return <StandardTableFormInputComponent {...editorProps} value={value} />
                },
                validator: (newValue, row, column) => {
                    if (!newValue) {
                        return { valid: false, message: <FormattedMessageString id="CMS.Table.validation.required" /> };
                    }
                }
            },

            {
                dataField: 'modules',
                text: <FormattedMessageString id="CMS.Pages.overview.table.modules" />,
                sort: false,
                isDummyField: true,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row, rowIndex) => {
                    if (row.page.type.indexOf('module') < 0) return;

                    let modules = JsonHelper.tryParse(row.page.modules);
                    if (!modules) return;

                    return <div className="actions">
                        {modules.map((id, index) => {
                            if (!this.state.modules[id]) return null;

                            let module = this.state.modules[id].module;
                            module.settings = JsonHelper.tryParse(module.settings);

                            return (
                                <ToolTipLink key={index} className="default" onClick={() => Module.change(this.state.modules[id])} to={"/module/" + module.settings.route + "/" + module.id + "/overview"}
                                    title={module.contents[this.state.language].title}>
                                    <FontAwesomeIcon icon={['far', module.settings.icon]} />
                                </ToolTipLink>
                            );

                        })}
                    </div>;
                }
            },
            {
                dataField: 'updated',
                text: <FormattedMessageString id="CMS.Pages.overview.table.last_updated" />,
                isDummyField: true,
                editable: false,
                formatter: (cellContent, row) => {
                    let contents = row.page.contents[index];
                    if (row.page.type === 'sitemap' || contents === undefined) return null;
                    return (
                        <div className="updated_by">
                            {moment(contents.updated_at).format('DD/MM/YYYY HH:mm:ss')}
                            {contents.updated_by && (<><br /><span className="small-highlighted-text"><FormattedMessageString id="CMS.Pages.overview.table.updated_by" /> {contents.updated_by}</span></>)}
                        </div>
                    )
                }
            },
            {
                dataField: 'views',
                text: '',
                sort: false,
                isDummyField: true,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    let contents = row.page.contents[index];
                    return (
                        <div className="actions">
                            {
                                contents && !GeneralSettingsHelper.is() ?
                                    <Link to="#" className="link px-1 link-auto">
                                        <FontAwesomeIcon icon={['fas', 'fa-chart-column']} className="mr-1" />
                                        {contents.views}
                                    </Link>
                                    : null
                            }
                        </div>
                    )
                }
            },
            {
                dataField: 'page-actions',
                isDummyField: false,
                text: <FormattedMessageString id="CMS.Table.actions" />,
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    let settings = JSON.parse(row.page.settings);
                    let contents = row.page.contents[index];

                    return (
                        <div className="actions">

                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"}
                                    to="#" title="CMS.Pages.overview.tooltip.permissions"
                                    onClick={() => this._userPermissions.openModal("page_permission", row.id)}
                                >
                                    <FontAwesomeIcon icon={['far', 'key']} />
                                </ToolTipLink>
                                : null
                            }
                            {CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"}
                                    to={"config/" + row.id + '/' + (contents !== undefined ? contents.language : null)}
                                    title={"CMS.Pages.overview.tooltip.config"}
                                >
                                    <FontAwesomeIcon icon={['far', 'tools']} />
                                </ToolTipLink>
                                : null
                            }
                            {settings.show_eyecatchers === true && CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"} to={"eyecatcher/" + row.id}
                                    title={"CMS.Pages.overview.tooltip.eyecatcher"}
                                >
                                    <FontAwesomeIcon icon={['far', 'eye']} />
                                </ToolTipLink>
                                : null
                            }
                            {settings.show_photoalbum === true && CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"} to={"photoalbum/" + row.id}
                                    title={"CMS.Pages.overview.tooltip.photoalbum"}
                                >
                                    <FontAwesomeIcon icon={['far', 'images']} />
                                </ToolTipLink>
                                : null
                            }
                            {settings.show_videoalbum === true && CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"}
                                    to={"videoalbum/" + row.id + '/' + (contents !== undefined ? contents.language : null)}
                                    title={"CMS.Pages.overview.tooltip.videoalbum"}
                                >
                                    <FontAwesomeIcon icon={['far', 'video-plus']} />
                                </ToolTipLink>
                                : null
                            }
                            {settings.background_images === true && CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"}
                                    to={"background-images/" + row.id}
                                    title={"CMS.Pages.overview.tooltip.background_images"}
                                >
                                    <FontAwesomeIcon icon={['far', 'image']} />
                                </ToolTipLink>
                                : null
                            }
                            {settings.background_videos === true && CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"} to={"background-videos/" + row.id}
                                    title={"CMS.Pages.overview.tooltip.background_videos"}
                                >
                                    <FontAwesomeIcon icon={['far', 'video']} />
                                </ToolTipLink>
                                : null
                            }
                            {row.page.type.toString() !== "dropdown" && row.page.type.toString() !== "url" ?
                                <ToolTipLink className={row.page.type.toString() === "sitemap" ? 'default disabled' : "default"}
                                    to={"seo/" + row.page.id + '/' + (contents !== undefined ? contents.id : null) + '/' + (contents !== undefined ? contents.language : null)}
                                    title={"CMS.Pages.overview.tooltip.seo"}
                                >
                                    <FontAwesomeIcon icon={['far', 'search']} />
                                </ToolTipLink>
                                : null
                            }
                        </div>
                    )
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                text: '',
                hidden: !CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR),
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    return <div className="actions">
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && !GeneralSettingsHelper.is() ?
                            row.page.active ?
                                <Link onClick={() => this.toggleActive(row)} className={row.page.type.toString() === "sitemap" ? 'active disabled' : "active"} to="#">
                                    <FontAwesomeIcon icon={['fas', 'check']} />
                                </Link>
                                :
                                <Link onClick={() => this.toggleActive(row)} className={row.page.type.toString() === "sitemap" ? 'inactive disabled' : "inactive"} to="#">
                                    <FontAwesomeIcon icon={['fas', 'times']} />
                                </Link>

                            : null
                        }
                        {CheckPermissions.role(this.props.user.roles, UserRolesEnum.EDITOR) && (['none', 'dropdown', 'module'].indexOf(row.page.type.toString()) < 0) ?
                            <TableEditActionComponent id={row.page.id} disabled={row.page.type.toString() === "sitemap"}
                                custom={row.page.contents[index] !== undefined ? '/' + row.page.contents[index].language : '/'} />
                            : null
                        }
                        {(CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) && (['text', 'url'].indexOf(row.page.type.toString()) > -1)) || CheckPermissions.role(this.props.user.roles, UserRolesEnum.RESELLER) ?
                            (!GeneralSettingsHelper.is() ?
                                <TableDeleteActionComponent row={row} parent={this} disabled={row.page.type.toString() === "sitemap"} /> : null)
                            : null
                        }
                    </div>
                }
            },
            {
                dataField: 'link',
                isDummyField: true,
                text: '',
                editable: false,
                headerStyle: (colum, colIndex) => {
                    return { width: '1%' };
                },
                formatter: (cellContent, row) => {
                    if (!row.page.contents || !row.page.contents[index]) return null

                    return <div className="actions">
                        <a href={row.domain + "/" + row.page.contents[index].language + "/" + row.page.contents[index].slug + '?mode=preview'}
                            className={"url"} target={"_blank"} rel="noopener noreferrer"
                        >
                            <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                        </a>
                    </div>
                }
            }
        ];
    }

    switch = (index) => {
        if (!GeneralSettingsHelper.is()) {
            return (
                <div className="toggle-tree" data-toggle="buttons" data-index={index}>
                    <button type="button" data-toggle="button" data-view-type={"table"} data-index={index}
                        onClick={this.toggle.bind(this)}
                        disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? null : 'disabled'}>
                        <FontAwesomeIcon icon={['far', 'th']} />
                    </button>
                    <button type="button" data-toggle="button" data-view-type={"tree"} data-index={index}
                        onClick={this.toggle.bind(this)}
                        disabled={this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ? 'disabled' : ''}>
                        <FontAwesomeIcon icon={['far', 'list']} />
                    </button>
                </div>
            )
        }
    }

    toggleActive = (_row) => PageRepository.toggle(_row.page.id)
        .then(response => {
            let newRows = [...this.state.rows];
            newRows = newRows.map((row) => {
                if (row.page.id === _row.page.id) {
                    let page = row.page;
                    page.active = response;
                    return {
                        ...row,
                        page: page
                    }
                }
                return row;
            })
            this.setState(curr => ({ ...curr, rows: newRows, reload: true }), () => {
                this.setState({ reload: false })
            });
        })
        .catch(error => FormToastComponent.errorTrans('CMS.Pages.overview.toggle.failed'));

    toggle = (event) => {
        let target = event.target.closest('button')
        let index = target.attributes['data-index'].value
        let viewType = target.attributes['data-view-type'].value

        this.props.parent.setState({ reload: true }, () => {
            this.props.parent.setState({ reload: false }, () => {
                let toggler = this.state.toggler;
                toggler[index] = viewType
                this.props.parent.table_ref.setState({
                    toggler: toggler,
                    language: this.state.language
                })
            })
        })
    }

    updateData = () => PageRepository.all().then(response => this.props.parent.setState({ rows: response }));

    table = (index) => (
        <div className={"custom-data-table pages"}>
            <StandardTableComponent keyField="id" data={this.state.rows} columns={this.columns(index)}
                title={this.props.title_id} type={this.props.type}
                custom={CheckPermissions.role(this.props.user.roles, UserRolesEnum.MANAGER) ? this.switch(index) : undefined}
                subRows={true}
                cellEdit={cellEditFactory({
                    mode: 'click',
                    blurToSave: true,
                    onStartEdit: this.handleInlineStartEdit.bind(this),
                    afterSaveCell: this.handleInlineEditAfterSave.bind(this)
                })}
            />
            <DeleteModalComponent parent={this} />
        </div>
    )

    tree = (index) => (
        <div className="custom-data-table pages">
            <div className="table-wrapper">
                <div className="table-header">
                    <FormattedMessage id="CMS.Pages.overview.tree.title">{(value) => <p>{value}</p>}</FormattedMessage>
                    {this.switch(index)}
                </div>
                <PageOverviewTreeComponent data={this.state.rows} index={index} parent={this} />
            </div>
        </div>
    )

    render() {
        if (this.state.isLoading) return <LoaderComponent />;
        if (this.state.reload) return <LoaderComponent />;

        return (
            <div className="tab-tables">
                <Tab.Container id={"list-group-language-pages"}
                    defaultActiveKey={"#" + (this.state.website.languages[this.state.language] !== undefined ? this.state.website.languages[this.state.language].language : this.state.website.languages[0].language)}>
                    <ListGroup>
                        {this.state.website.languages.map((language, index) => {
                            return (
                                <ListGroup.Item action href={"#" + language.language} key={index}
                                    onClick={() => this.setState({ language: index }, () => Application.setTableLanguage(index))}>
                                    {CountryHelper.getLanguages().find(({ value }) => value + "" === language.language + "").label}
                                </ListGroup.Item>
                            )
                        })
                        }
                    </ListGroup>
                    <Tab.Content>
                        {this.state.website.languages.map((language, index) => {
                            return <Tab.Pane eventKey={"#" + language.language} key={index}>
                                {this.state.toggler[index] !== undefined && this.state.toggler[index] === "tree" ?
                                    this.tree(index)
                                    :
                                    this.table(index)
                                }
                            </Tab.Pane>

                        })}
                    </Tab.Content>
                </Tab.Container>

                <UserToItemPermissionsComponent name="page_permission" parent={this} title="CMS.page.permissions.title" type="page" onRef={ref => this._userPermissions = ref} />
            </div>
        );
    }

    _editableIndexes = null;
    handleInlineEditAfterSave = (oldValue, newValue, row, column) => {
        this.handleInlineStartEdit();

        let content = row.page.contents[this.state.language];
        if (content) {
            oldValue = content.title;
        }

        if (oldValue === newValue) return;

        PageRepository.update({ id: row.page.id, language: content.language, settings: { ...JSON.parse(row.page.settings) }, title: newValue, modules: row.page.modules })
            .then(() => {
                FormToastComponent.successTrans("Pages", "Default.saved");
                this.props.parent.props.history.push('/pages/overview');

                if (this.props.parent.props.parent) {
                    this.props.parent.props.parent.reload();
                }
            })
            .catch(() => FormToastComponent.errorTrans("Default.error"));
    }

    handleInlineStartEdit = () => {
        TableHelper.focusEditCell();

        setTimeout(() => {
            let mainRows = document.querySelectorAll('.main-row');
            mainRows.forEach(tr => {
                if (tr.childNodes[1]) tr.childNodes[1].setAttribute('colspan', 3);
            })

            let subRows = document.querySelectorAll('.sub-row.one');
            subRows.forEach(tr => {
                if (tr.childNodes[1]) tr.childNodes[1].setAttribute('colspan', 2);
            })
        });
    }

    initEscListener = (e) => {
        if (e.keyCode !== 27) return;
        this.handleInlineStartEdit();
    }
}

PageOverviewTableComponent.propTypes = {
    title_id: PropTypes.string.isRequired,
    rows: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
    user: PropTypes.object.isRequired,
    parent: PropTypes.object.isRequired
}

export default PageOverviewTableComponent;
